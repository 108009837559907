export default (theme) => ({
	container: {
		// minHeight: theme.typography.pxToRem(85),
		padding: theme.typography.pxToRem(5),
		width: "100%",
	},
	avataarStyle: {
		// backgroundColor: "transparent",
		// borderColor: "#56D9FE",
	},
	dividerContainer: {
		margin: `${theme.typography.pxToRem(20)} 0px`,
	},
	headerStyle: {
		color: "#56D9FE ",
	},

	outerCard: {
		padding: theme.typography.pxToRem(60),
	},
	titleStyle: {
		textTransform: "capitalize",
	},

	innerCard: {
		minHeight: theme.typography.pxToRem(224),
		padding: ` ${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(
			42
		)}  ${theme.typography.pxToRem(12)}`,
	},

	bordered: {
		border: "1px solid #14004708",
		borderRadius: theme.typography.pxToRem(10),
	},

	iconContainer: {
		textAlign: "center",
		alignSelf: "center",
	},

	iconButton: {
		padding: theme.typography.pxToRem(4),
	},

	lowerGrid: {
		padding: theme.typography.pxToRem(15),
	},

	imageStyle: {
		width: "100%",
		height: theme.typography.pxToRem(214),
	},

	// headerStyle: {
	// 	fontSize: theme.typography.pxToRem(18),
	// },

	subHeaderStyle: {
		fontSize: theme.typography.pxToRem(12),
	},

	headerContainer: {
		alignSelf: "center",
	},
	tableHeader: {
		backgroundColor: "#F5F6FA",
		minHeight: theme.typography.pxToRem(45),
		alignItems: "center",
		padding: theme.typography.pxToRem(10),
		textTransform: "uppercase",
		fontWeight: "bold !important",
	},
	tableCard: {
		padding: theme.typography.pxToRem(20),
	},
	tableGrid: {
		alignSelf: "flex-end",
	},
	paddedGrid: {
		paddingTop: '20px'
	}
});
