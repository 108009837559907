import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from "./style";

const CustomTable = ({ columns, rows, handleDelete, handleEdit }) => {
	const { tableContainer } = useStyles();

	return (
		<TableContainer className={tableContainer}>
			<Table stickyHeader aria-label="sticky table">
				<TableHead>
					<TableRow>
						{columns?.map((column, index) => (
							<TableCell key={index}>
								<b>{column}</b>
							</TableCell>
						))}
						<TableCell >
							Actions
						</TableCell>
					</TableRow>

				</TableHead>
				<TableBody>
					{rows?.map((row) => {
						return (
							<TableRow hover tabIndex={-1} key={row?.code}>
								{columns?.map((column) => {
									console.log({ column });
									const value = row[column.split("_")[0]]
										? row[column.split("_")[0].toLowerCase()]
										: row[column.replace(" ", "_").toLowerCase()];
									return (<>
										<TableCell>{value}</TableCell>

									</>);
								})}
								{console.log({row}, row.id)}
								<TableCell>
									<IconButton onClick={()=>handleDelete(row.id)} color="secondary" aria-label="delete" component="span">
										<DeleteIcon />
									</IconButton>
									<IconButton onClick={()=>handleEdit(row.id)} color="secondary" aria-label="edit" component="span">
										<EditIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

CustomTable.propTypes = {
	columns: PropTypes.shape([]).isRequired,
};

export default CustomTable;
