import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import useStyles from "./styles";
import CustomButton from "../../components/buttons/CustomButton";

const AccountForm = ({ handleAccountData, getData }) => {
	const { typographyStyle, formContainer } = useStyles();
	const [account, setAccounts] = useState([]);

	const gstRates = [
		{
			value: "5",
			label: "5%",
		},
		{
			value: "12",
			label: "12%",
		},
		{
			value: "18",
			label: "18%",
		},
		{
			value: "28",
			label: "28%",
		},
	];

	const handleAccountDetails = (e) => {
		const { name, value } = e.target;
		setAccounts({ ...account, [name]: value });
	};
	console.log({ account });

	useEffect(() => {
		handleAccountData({ acc_details: account });
	}, [account]);

	return (
		<form>
			<Grid container spacing={3} className={formContainer}>
				<Grid item xs={12}>
					<Typography variant="h6"> Account Details</Typography>
				</Grid>
				{/* {new Array(totalProducts).fill("").map((_, index) => ( */}
				<>
					<Grid item xs={6} md={4} lg={3}>
						<Typography className={typographyStyle}>
							A/c Holder's Name
						</Typography>
						<TextField
							name="account_holder"
							fullWidth
							variant="outlined"
							size="small"
							onChange={handleAccountDetails}
						/>
					</Grid>
					<Grid item xs={6} md={4} lg={3}>
						<Typography className={typographyStyle}>Bank Name</Typography>
						<TextField
							name="bank_name"
							fullWidth
							variant="outlined"
							size="small"
							onChange={handleAccountDetails}
						/>
					</Grid>
					<Grid item xs={6} md={4} lg={3}>
						<Typography className={typographyStyle}>A/c No.</Typography>
						<TextField
							name="acc_no"
							fullWidth
							variant="outlined"
							size="small"
							onChange={handleAccountDetails}
						/>
					</Grid>
					<Grid item xs={6} md={4} lg={3}>
						<Typography className={typographyStyle}>Branch</Typography>
						<TextField
							name="branch"
							fullWidth
							variant="outlined"
							size="small"
							onChange={handleAccountDetails}
						/>
					</Grid>
					<Grid item xs={6} md={4} lg={3}>
						<Typography className={typographyStyle}>IFSC</Typography>
						<TextField
							name="ifsc"
							fullWidth
							variant="outlined"
							size="small"
							onChange={handleAccountDetails}
						/>
					</Grid>
					<Grid item xs={6} md={4} lg={3}>
						<Typography className={typographyStyle}>PAN Code</Typography>
						<TextField
							name="pan"
							fullWidth
							variant="outlined"
							size="small"
							onChange={handleAccountDetails}
						/>
					</Grid>
				</>
				{/* ))} */}
				{/* <Grid item xs={2}>
					<CustomButton
						isOutlined
						label="Add New Item"
						onButtonClick={handleAddMore}
					/>
				</Grid> */}
			</Grid>
		</form>
	);
};

AccountForm.propTypes = {};

export default AccountForm;
