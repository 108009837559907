import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import useStyles from "./styles";
import CustomButton from "../../components/buttons/CustomButton";
import { ToWords } from "to-words";
import NumberFormatter from "../../utils/NumberFormatter";

const ProductForm = ({ handleProductData }) => {
	const { typographyStyle, formContainer } = useStyles();
	const [products, setProducts] = useState([]);
	const [totalProducts, setTotalProducts] = useState(1);

	const handleAddMore = () => {
		setTotalProducts(totalProducts + 1);
	};

	const gstRates = [
		{
			value: 5,
			label: "5%",
		},
		{
			value: 12,
			label: "12%",
		},
		{
			value: 18,
			label: "18%",
		},
		{
			value: 28,
			label: "28%",
		},
	];

	const handleProductDetails = (e, index) => {
		const { name, value } = e.target;
		let tempProduct = products;
		let total =
			tempProduct[index]?.quantity * tempProduct[index]?.rate +
			(tempProduct[index]?.gst / 100) *
				tempProduct[index]?.quantity *
				tempProduct[index]?.rate;
		tempProduct[index] = {
			...tempProduct[index],
			[name]: value,
		};
		setProducts([...tempProduct]);
	};

	useEffect(() => {
		let totalAmount = 0;
		let gstAmount = 0;
		let amountWords = 0;
		console.log({ products });
		products?.map((row) => {
			const totalRate = row.quantity * row.rate;
			totalAmount = totalAmount + totalRate;
			const gst = row.gst / 100;
			gstAmount = gstAmount + totalRate * gst;
		});
		// setSubTotal(totalAmount);
		// setTotalGst(N);
		// setTotal();
		const toWords = new ToWords();
		const finalAmt = NumberFormatter.roundOff(totalAmount + gstAmount, 2);
		if (!isNaN(finalAmt)) {
			amountWords = toWords.convert(finalAmt);
		}

		handleProductData({
			goods: products,
			total_amount_without_tax: totalAmount,
			total_tax: NumberFormatter.roundOff(gstAmount, 2),
			total_amount: NumberFormatter.roundOff(totalAmount + gstAmount, 2),
			total_amount_in_words: amountWords,
		});
	}, [products]);

	return (
		<form>
			<Grid container spacing={3} className={formContainer}>
				<Grid item xs={12}>
					<Typography variant="h6"> Product Details</Typography>
				</Grid>
				{new Array(totalProducts).fill("").map((_, index) => (
					<>
						<Grid item xs={6} md={4} lg={3}>
							<Typography className={typographyStyle}>Particulars</Typography>
							<TextField
								name="item"
								fullWidth
								variant="outlined"
								size="small"
								onChange={(e) => handleProductDetails(e, index)}
							/>
						</Grid>
						{/* <Grid item xs={6} md={4} lg={3}>
							<Typography className={typographyStyle}>Description</Typography>
							<TextField
								name="description"
								fullWidth
								variant="outlined"
								size="small"
								onChange={(e) => handleProductDetails(e, index)}
							/>
						</Grid> */}
						<Grid item xs={6} md={4} lg={3}>
							<Typography className={typographyStyle}>GST Rate</Typography>
							<TextField
								name="gst"
								select
								fullWidth
								variant="outlined"
								size="small"
								onChange={(e) => handleProductDetails(e, index)}
							>
								{gstRates.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={6} md={4} lg={3}>
							<Typography className={typographyStyle}>Quantity</Typography>
							<TextField
								name="quantity"
								fullWidth
								type="number"
								variant="outlined"
								size="small"
								onChange={(e) => handleProductDetails(e, index)}
							/>
						</Grid>
						<Grid item xs={6} md={4} lg={3}>
							<Typography className={typographyStyle}>Rate</Typography>
							<TextField
								name="rate"
								fullWidth
								type="number"
								variant="outlined"
								size="small"
								onChange={(e) => handleProductDetails(e, index)}
							/>
						</Grid>
						{/* <Grid item xs={9} /> */}
					</>
				))}
				<Grid item xs={2}>
					<CustomButton
						isOutlined
						label="Add New Item"
						onButtonClick={handleAddMore}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

ProductForm.propTypes = {};

export default ProductForm;
