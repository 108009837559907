import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Card,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	OutlinedInput,
	Typography,
} from "@material-ui/core";
import DetailCard from "../../components/cards/DetailCard/DetailCard";
import CustomButton from "../../components/buttons/CustomButton/CustomButton";
import CustomChip from "../../components/chips/CustomChip";
import StarIcon from "@material-ui/icons/Star";
import useStyles from "./styles";
import TeamCard from "../../components/cards/TeamCard/TeamCard";
import CachedIcon from "@material-ui/icons/Cached";
import QuickDetailCard from "../../components/cards/QuickDetailCard";
import BasicLayout from "../../components/BasicLayout";
import OverviewCard from "../../components/cards/OverviewCard/OverviewCard";
import TableCard from "../../components/cards/TableCard";
import DropdownMenu from "../../components/DropdownMenu";
import SimpleDropdown from "../../components/SimpleDropdown";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import ButtonCard from "../../components/cards/ButtonCard";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import { useHistory } from "react-router";

const InvoiceAcceptancePage = ({ loadPrimary }) => {
	const {
		tableHeader,
		content,
		searchStyle,
		cardStyle,
		productLayout,
		tableCard,
		starIcon,
		divider,
		quickDetail,
		chartCard,
		chartContainer,
		avataar,
		iconStyle,
	} = useStyles();

	const [returnModalOpen, setReturnModalOpen] = useState(false);
	const notifications = useRecoilValue(userNotification);
	const history = useHistory();

	const buttons = [
		{
			id: "1",
			title: "Incoming Invoice",
			text: "Add Incoming Invoices",
			// setModalOpen: setReturnModalOpen,
		},
		{
			id: "2",
			title: "Outgoing Invoice",
			text: "Add Outgoing Invoices",
			// setModalOpen: setReturnModalOpen,
		},
	];

	const handleChange = () => {};

	useEffect(() => {
		loadPrimary(false);
	}, []);

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			//hit search
		}
	};

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid
				container
				className={content}
				justifyContent="center"
				alignItems="center"
				spacing={3}
			>
				{buttons.map((button) => (
					<Grid item xs={4}>
						<ButtonCard
							id={button.id}
							buttonTitle={button.title}
							textValue={button.text}
							onCardClick={() =>
								history.push(`/app/invoices/${button.title.split(" ")[0]}`)
							}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

InvoiceAcceptancePage.propTypes = {};

export default InvoiceAcceptancePage;
