import intIcon from "../icons/svgs/123.svg";
import stringIcon from "../icons/svgs/abc.svg";
import dateIcon from "../icons/svgs/dateIcon.svg";

const BASE_PATH = "/app";

export const Basic_Navs = {
	dashboard: { index: 0, path: `${BASE_PATH}/dashboard` },
	// inventory: { index: 1, path: `${BASE_PATH}/inventory` },
	// orders: { index: 2, path: `${BASE_PATH}/orders` },
	delivery: { index: 1, path: `${BASE_PATH}/delivery` },
	// orders: { index: 2, path: `${BASE_PATH}/orders` },
	invoices: { index: 2, path: `${BASE_PATH}/invoices` },
	teams: { index: 3, path: `${BASE_PATH}/teams` },
	bills: { index: 4, path: `${BASE_PATH}/bills` },
	activity: { index: 5, path: `${BASE_PATH}/activity` },
	conveyance: { index: 6, path: `${BASE_PATH}/conveyance` },


	// help: { index: 5, path: `${BASE_PATH}/help` },
	// notifications: { index: 6, path: `${BASE_PATH}/notifications` },
	// settings: { index: 7, path: `${BASE_PATH}/settings` },
};

export const invoiceData = {
	"id":25,
	"acc_details":{
	   "pan":"AHDPA6172H",
	   "ifsc":"PUNB0002520",
	   "acc_no":"0025050024028",
	   "branch":"Bokakhat",
	   "bank_name":"Punjab National Bank",
	   "account_holder":"M/S Arunudai Suppliers"
	},
	"invoice_to":{
	   "city":"Bangaluru",
	   "name":"Optimising Resources Private Limited",
	   "gstin":"29AACCO9797R1ZI",
	   "state":"Karnataka"
	},
	"invoice_from":{
	   "city":"Bokakhat",
	   "name":"M/S Arunudai Suppliers",
	   "gstin":"18AHDPA6172H1ZI",
	   "state":"Assam",
	   "contact":"8486640662",
	   "pancode":"AHDPA6172H",
	   "vendorcode":"6778"
	},
	"shipping_details":{
		"city":"Bangaluru",
		"name":"Optimising Resources Private Limited",
		"gstin":"29AACCO9797R1ZI",
		"state":"Karnataka"
	 },
	"buyers_details":{
		"buyer_order_no": "12",
		"buyer_date" : "15-10-21",
		"dispatch_doc_no" : "12",
		"delivery_note_date": "11-09-21",
		"dispaatchhed_through": " ",
		"destination" : " ",
		"terms_of_delivery": "None",


	},
	"goods":[
	   {
		  "id":1,
		  "gst":18,
		  "item":"Warehouse Now",
		  "rate":"600",
		  "total":"40,002.00",
		  "quantity":"56.5",
		  "desc": 'Backend (Node JS)'
	   }
	],
	"total_amount_without_tax":"33,900.00",
	"total_tax":"6,102.00",
	"total_amount":"40,002.00",
	"total_amount_in_words":"Twenty Five Thousand and Two Only",
	"status":"Pending",
	"invoice_type":"Incoming",
	"type":"None",
	"state":"Assam",
	"stateCode":"18",
	"pzone":"NOrth",
	"hsncode":"456",
	"invoice_no": "14557",
	"invoice_date": "27-01-22",
	"mode_of_payment": "Bank Transfer",
	"suppliers_reference": "43344",
	"other_reference": "None",
	"delivery_note": "None",
	"paid": 15000
 }

export const PERSISTENT_AUTHENTICATION = "tvcpsess";
