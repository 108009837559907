import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles({
	tableHeader: {
		backgroundColor: "#F5F6FA",
		minHeight: theme.typography.pxToRem(45),
		alignItems: "center",
		padding: theme.typography.pxToRem(10),
	},
	tableCard: {
		padding: theme.typography.pxToRem(20),
	},
	productLayout: {
		margin: theme.typography.pxToRem(15),
	},
	container: {
		// minHeight: theme.typography.pxToRem(85),
		padding: theme.typography.pxToRem(5),
		width: "100%",
	},
	content: {
		// paddingTop: theme.typography.pxToRem(20)
		paddingTop: theme.typography.pxToRem(40),
		paddingLeft: theme.typography.pxToRem(150),
		paddingRight: theme.typography.pxToRem(50),
		backgroundColor: "#F0F0F7",

	},
	cardStyle: {
		width: "100%",
		padding: theme.typography.pxToRem(20),
	},
	starIcon: {
		display: "flex",
	},
	divider: {
		marginTop: theme.typography.pxToRem(10),
	},
	chartCard: {
		padding: theme.typography.pxToRem(30),
	},
	chartContainer: {
		marginTop: "30px",
		padding: '30px',
		minHeight: '392px',
	},
});

export default useStyles;
