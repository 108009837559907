import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles";

const CustomButton = ({
	label,
	isOutlined,
	isDisabled,
	startIcon,
	endIcon,
	onButtonClick,
	color,
	size
}) => {
	const classes = useStyles();
	const handleClick = (event) => {
		if (onButtonClick) onButtonClick(event);
	};
	return (
		<Button
			variant={isOutlined ? "outlined" : "contained"}
			color="primary"
			className={color ? classes.buttonStyle : classes.bordered}
			isDisabled={isDisabled}
			size={size}
			startIcon={
				startIcon && (
					<img className={classes.image} src={startIcon} alt="" />
				)
			}
			endIcon={
				endIcon && (
					<img className={classes.image} src={endIcon} alt="" />
				)
			}
			onClick={(event) => handleClick(event)}
		>
			{label}
		</Button>
	);
};

CustomButton.propTypes = {
	label: PropTypes.string.isRequired,
	isOutlined: PropTypes.bool,
	isDisabled: PropTypes.bool,
	startIcon: PropTypes.node,
	endIcon: PropTypes.node,
	onButtonClick: PropTypes.func,
	tablePage: PropTypes.bool,
};

CustomButton.defaultProps = {
	isOutlined: false,
	isDisabled: false,
	startIcon: undefined,
	endIcon: undefined,
	onButtonClick: () => {},
};

export default CustomButton;
