import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		alignSelf: "center",
	},
	quickDetail: {
		padding: theme.typography.pxToRem(10),
	},
}));

export default useStyles;
