import { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { navBasic } from "../Navigations.atom";
import { Basic_Navs } from "./constants";

/**
 * @method useBasicNav
 */
export const useBasicNav = (current) => {
	const [currentActive, setCurrentActive] = useRecoilState(navBasic);

	const history = useHistory();

	useEffect(() => {
		if (current) setCurrentActive(Basic_Navs[current].index);
	}, [current]);
	/**
	 * @method setActiveTab - mark the tab as active and change the styling of the nav element
	 * @param {Boolean=} autoRedirect=true - defaults to true, redirects automatically to the related route.
	 */
	const setActiveTab = (tab, autoRedirect = true) => {
		console.log("tab", tab);
		let { index, path = "/" } = Basic_Navs[tab];
		setCurrentActive(index);
		console.log("index", index, path);
		if (autoRedirect) history.push(path);
	};

	return { activeTab: currentActive, setActiveTab };
};
