import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FrameIcon from "../../icons/svgs/Frame.svg";
import CloseIcon from "../../icons/svgs/close.png";
import minimizeIcon from "../../icons/svgs/minimize.svg";
import PropTypes from "prop-types";
import useStyles from "./style";

/**
 * @method Popup - popup component of material ui
 * @param {Object} props - props passed to the component
 * @param {() => void} props.handleClose - to close the popup
 * @param {Object} props.children - components passed as children
 * @param {string} props.name - title of popup
 * @param {boolean} props.open - when true, opens the popup
 */

const Popup = (props) => {
	const { open, name, handleClose, children, maxWidth } = props;
	const classes = useStyles();

	console.log({ maxWidth });

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				classes={{ paper: classes.dialogWrapper }}
				aria-labelledby='form-dialog-title'
				maxWidth={maxWidth ? maxWidth : "md"}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.title}>
						<img src={FrameIcon} alt='icon'></img>
						<h3 className={classes.titleText}>{name}</h3>
						<img
							onClick={handleClose}
							className={classes.close}
							src={minimizeIcon}
							alt='close'
						></img>
						<img
							onClick={handleClose}
							style={{ cursor: "pointer" }}
							src={CloseIcon}
							alt='close'
						></img>
					</div>
				</DialogTitle>
				<DialogContent classes={{ root: classes.content }}>
					{children}
				</DialogContent>
			</Dialog>
		</div>
	);
};

Popup.propTypes = {
	handleClose: PropTypes.func,
	name: PropTypes.string,
	open: PropTypes.bool,
	children: PropTypes.object,
};

export default Popup;
