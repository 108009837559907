import axios from "./axios.global";

export const getStateDetails = async () => {
	try {
		let response = await axios.get("/state-codes/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
