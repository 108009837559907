import { createTheme, responsiveFontSizes } from "@material-ui/core";

const theme = responsiveFontSizes(
	createTheme({
		palette: {
			standard: { main: "#43425D", contrastText: "#DEF0F2" },
			primary: { main: "#43425D", contrastText: "#DEF0F2" },
			secondary: { main: "#BCBCCB", contrastText: "#FFFFFF" },
			info: { main: "#A3A0FB", contrastText: "#FFFFFF" },
		},
		typography: {
			fontFamily: "Source Sans Pro, sans-serif,Poppins",
			button: {
				textTransform: "none",
				padding: "14px 31px",
			},
			allVariants: {
				color: "#43425D",
			},
		},
		overrides: {
			MuiCssBaseline: {
				"@global": {
					"@font-face": [
						{
							fontFamily: "Source Sans Pro, sans-serif,Poppins",
							fontStyle: "normal",
							fontDisplay: "swap",
							fontWeight: 400,
						},
					],
				},
			},
			MuiButton: {
				root: {
					fontWeight: 600,
					color: "#FFFFFF",
					backgroundColor: "#43425d",
					borderRadius: "4px",
					border: '1px solid #43425d'
					// width: "100%",
					// border: "1px solid #000000",
				},
				textSizeSmall: {
					backgroundColor: "transparent",
					color: "#3B86FF",
				},
				textSizeLarge: {
					backgroundColor: "transparent",
					color: "#3B86FF",
				},
				outlinedPrimary: {
					backgroundColor: "transparent",
					border: "1px solid #00000008",
					color: "#43425D",
					borderRadius: "5px",
				},
			},
			MuiTextField: {
				root: {
					color: "#43425D",
					backgroundColor: "#f3f3f4",
					border: "0px",
				},
			},
			MuiOutlinedInput: {
				input: {
					borderRadius: '8px',
					border: 'none'
				}
			},
			MuiInputBase: {
				root: {
					borderColor: "red",
				},
				input: {
					"&:focus": {
						borderRadius: "10px !important",
					},
				},
			},
			MuiDataGrid: {
				row: {
					border: "1px solid",
				},
			},
			MuiSelect: {
				outlined: {
					border: "1px solid #00000008",
					borderRadius: 10,
				},
				select: {
					"&:focus": {
						background: "transparent !important",
					},
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: "16px",
					color: "#000000",
				},
			},
			MuiCheckbox: {
				root: {
					"&$checked": {
						color: "#6633F2 !important",
					},
				},
			},
			MuiTableHead: {
				root: {
					backgroundColor: "#F5F6FA",
				},
			},
			MuiNativeSelect: {
				root: {
					borderColor: "#6633F2 !important",
				},
				outlined: {
					border: "1px solid #6633F2 !important",
					borderRadius: 10,
				},
				selectMenu: {
					padding: "1rem",
				},
				select: {
					"&:focus": {
						background: "transparent !important",
					},
					"&:not([multiple]) option": {
						backgroundColor: "#6633F2 !important",
						padding: "1rem",
						borderRadius: "5px",
					},
				},
				// select: {
				//   fontSize: '1rem',
				//   "&:focus": {
				//     background: "#6633F2 !important",
				//   },
				// },
			},
			MuiDialogTitle: {
				root: {
					padding: "5px 12px",
				},
			},
		},
	})
);

export default theme;
