import axios from "./axios.global";

export const generateConveyance = async (payload) => {
	try {
		let response = await axios.post("/conveyance/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const generateConveyanceBulk = async (payload) => {
	try {
		let response = await axios.post(`/conveyance/bulk/`, payload, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllConveyance = async (status) => {
	try {
		let api = `/conveyance/`;
		let response = await axios.get(api);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
	
};

export const getAllConveyanceBulk = async (status) => {
	try {
		let api = `/conveyance/bulk/`;
		let response = await axios.get(api, {responseType: 'blob'});
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
}

export const editConveyance = async (id, payload, status) => {
	try {
		let api = `/conveyance/${id}/`;
		let response = await axios.put(api, payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
}

export const deleteConveyance = async (id, status) => {
	try {
		let api = `/conveyance/${id}/`;
		let response = await axios.delete(api);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
}