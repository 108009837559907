import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import CustomTable from "../../tables/CustomTable";
import useStyles from "../../../pages/DashboardPage/styles";

const TableCard = ({ header, headerChild, columns, rows, isCard, handleRowEdit, handleRowDelete }) => {
	const { chartContainer, headerStyle } = useStyles();
	const cardLayout = (
		<Grid container spacing={3}>
			<Grid item xs={10} className={headerStyle}>
				<Typography variant="h5">{header}</Typography>
			</Grid>
				{headerChild}
			<CustomTable columns={columns} rows={rows} handleDelete={handleRowDelete} handleEdit={handleRowEdit} />
		</Grid>
	);
	return !isCard ? (
		<Card className={chartContainer}>{cardLayout}</Card>
	) : (
		<>{cardLayout}</>
	);
};

TableCard.propTypes = {};

export default TableCard;
