import {
	AppBar,
	Avatar,
	Badge,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Toolbar,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BrandImageIcon } from "../../../assets/images";
import MenuDropdown from "../../fields/MenuDropdown";
import SearchField from "../../fields/SearchField";
import { useStyles } from "./Style";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LanguageIcon from "@material-ui/icons/Language";
import ForumIcon from "@material-ui/icons/Forum";
import clsx from "clsx";
import SimpleMenu from "../../DropdownMenu/DropdownMenu";
import CustomButton from "../../buttons/CustomButton";
import {
	getPersistentAuthentication,
	resetPersistentAuthentication,
} from "../../../utils/functions";
import { useHistory } from "react-router";

function TopNav({ navState, notifcations }) {
	const { dividerStyle, ...classes } = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = useState(false);
	const [openDropdown, setOpenDropdown] = React.useState(false);
	const history = useHistory();

	const handleDropdown = (event) => {
		setAnchorEl(event.currentTarget);
		setOpenDropdown(true);
	};

	const handleDropdownClose = () => {
		setAnchorEl(null);
		const authentication = getPersistentAuthentication();
		if (authentication.rememberMe) {
		} else {
			resetPersistentAuthentication();
		}
		setOpenDropdown(false);
		history.push("/app/sign-in");
	};

	const handleClose = () => {
		// setAnchorEl(null);
		setOpenDropdown(false);
	};

	useEffect(() => {
		setOpen(navState);
	}, [navState]);

	// const menubar = notifcations?.map((notification) => (
	// 	<MenuItem onClick={handleDropdownClose}>{notification}</MenuItem>
	// ));
	const menubar = <MenuItem onClick={handleDropdownClose}>Sign Out</MenuItem>;

	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Toolbar>
				<div className={classes.profileAvatarContainer}>
					<IconButton>
						<LanguageIcon fontSize="large" color="secondary" />
					</IconButton>
					<IconButton>
						<ForumIcon fontSize="large" color="secondary" />
					</IconButton>
					<IconButton>
						<Badge badgeContent={"1"} color="primary" variant="standard">
							<NotificationsIcon fontSize="large" color="secondary" />
						</Badge>{" "}
					</IconButton>

					<Divider className={dividerStyle} orientation="vertical" />
					<Typography>Rana Chetia</Typography>
					{/* <SimpleMenu
						children={menubar}
						anchorEl={anchorEl}
						open={openDropdown}
						// close={handleClose}
						// close={handleDropdownClose}
					/> */}

					{/* <CustomButton label="Rana Chetia" onClick={handleDropdown} /> */}
					<IconButton onClick={handleDropdown}>
						<Avatar className={classes.avatar}>RC</Avatar>
					</IconButton>
					<SimpleMenu
						children={menubar}
						anchorEl={anchorEl}
						open={openDropdown}
						close={handleClose}
						// close={handleClose}
					></SimpleMenu>
				</div>
			</Toolbar>
		</AppBar>
		// <AppBar position="fixed" className={classes.nav} color="primary">
		// 	<Grid container>
		// 		{/* <img
		// 			src={BrandImageIcon}
		// 			alt="Tv Vizualize"
		// 			className={classes.brandImage}
		// 		/> */}
		// 		<MenuIcon fontSize="medium" />

		// 		<div className={classes.profileAvatarContainer}>
		// 			<Avatar className={classes.avatar}>BD</Avatar>
		// 		</div>
		// 	</Grid>
		// </AppBar>
	);
}

export default TopNav;
