import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	Avatar,
	Card,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	SvgIcon,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import styles from "./styles";

const useStyles = makeStyles(styles);

const DetailCard = ({ id, num, textValue, onCardClick, icon }) => {
	const {
		bordered,
		outerCard,
		container,
		avataarStyle,
		innerCard,
		iconContainer,
		titleStyle,
		lowerGrid,
		iconButton,
		imageStyle,
		headerStyle,
		subHeaderStyle,
		headerContainer,
	} = useStyles();

	return (
		<Card className={container} onClick={() => onCardClick(id)}>
			<Grid
				container
				alignContent="center"
				className={outerCard}
				spacing={3}
				justifyContent="center"
			>
				<Grid item xs={2}>
					{icon ? (
						<img src={icon} />
					) : (
						<Avatar className={avataarStyle}>
							<ShoppingCartOutlinedIcon />
						</Avatar>
					)}
				</Grid>
				<Grid item xs={10}>
					{/* <Grid container direction="column"> */}
					<Grid item xs={12}>
						<Typography variant="h6" className={headerStyle}>
							{num}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" className={titleStyle}>
							{textValue}
						</Typography>
					</Grid>
					{/* </Grid> */}
				</Grid>
			</Grid>
		</Card>
	);
};

DetailCard.propTypes = {
	image: PropTypes.string,
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string.isRequired,
	menuItems: PropTypes.shape([
		{
			value: PropTypes.string,
			onClick: PropTypes.func,
		},
	]),
};

DetailCard.defaultProps = {
	image: "",
	menuItems: [],
};

export default DetailCard;
