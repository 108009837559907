import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Avatar,
	Box,
	Card,
	Chip,
	Divider,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	SvgIcon,
	TextField,
	Typography,
} from "@material-ui/core";
import CustomButton from "../../components/buttons/CustomButton";
// import AddIcon from "@material-ui/icons/Add";
// import { addIcon } from "../../../assets/images";
import NumberFormatter from "../../utils/NumberFormatter";
import DisplayTable from "../../components/tables/DisplayTable";
import { useParams } from "react-router";
import { getInvoiceByID } from "../../apis/invoice.apis";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import styles from "./styles";

const useStyles = makeStyles(styles);

const InvoicePrintable = ({ loadPrimary }) => {
	const {
		bordered,
		outerCard,
		container,
		tableHeader,
		tableGrid,
		avataarStyle,
		innerCard,
		iconContainer,
		titleStyle,
		lowerGrid,
		iconButton,
		imageStyle,
		headerStyle,
		subHeaderStyle,
		headerContainer,
		dividerContainer,
		paddedGrid,
	} = useStyles();

	const { id } = useParams();
	const layoutRef = useRef();
	const [invoiceData, setInvoiceData] = useState();
	const [subtotal, setSubTotal] = useState();
	const [gst, setTotalGst] = useState();
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState();
	const [totalQuantity, setTotalQuantity] = useState();
	let rowId = 0;
	const columns = [
		{ "Sl.no": "id" },
		{ Particulars: "item" },
		{ Quantity: "quantity" },
		// { "GST(in %)": "gst" },
		{ Rate: "rate" },
		{ "Amount(INR)": "total" },
	];

	useEffect(() => {
		getInvoiceByID(id)
			.then((res) => {
				console.log({ res });
				setInvoiceData(res);
				setRows([
					...res.goods,
					{ quantity: `${res.total_quantity} Nos`, total: res.total_amount },
				]);
			})
			.catch((err) => console.log(err));
		if (loadPrimary) {
			loadPrimary(false);
		}
	}, []);

	useEffect(() => {
		let totalAmount = 0;
		let gstAmount = 0;
		invoiceData?.goods?.map((row) => {
			const totalRate = row.quantity * row.rate;
			totalAmount = totalAmount + totalRate;
			const gst = row.gst / 100;
			gstAmount = gstAmount + totalRate * gst;
		});
		setSubTotal(totalAmount);
		setTotalGst(NumberFormatter.roundOff(gstAmount, 2));
		setTotal(NumberFormatter.roundOff(totalAmount + gstAmount, 2));
	}, [invoiceData]);

	const handlePrint = () => {
		window.print();
	};
	const handleDownload = () => {
		window.scrollTo(0, 0);
		console.log("in download", layoutRef);

		html2canvas(layoutRef.current, {
			scale: 1,
			width: document.documentElement.scrollWidth,
			height: document.documentElement.scrollHeight,
		}).then((canvas) => {
			console.log("downloading");

			const imgData = canvas.toDataURL("image/jpeg");
			console.log("img", imgData);
			// let url = URL.createObjectURL(imgData);
			// let a = document.createElement("a");
			// a.href = imgData;
			// a.download = "title";
			// a.target = "_blank";
			// a.click();
			const pdf = new jsPDF();
			var width = pdf.internal.pageSize.getWidth();
			var height = pdf.internal.pageSize.getHeight();
			const widthRatio = width / canvas.width;
			const heightRatio = height / canvas.height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

			const canvasWidth = canvas.width * ratio;
			const canvasHeight = canvas.height * ratio;

			pdf.addImage(imgData, "JPEG", 10, 10, canvasWidth, canvasHeight);
			// pdf.output('dataurlnewwindow');
			pdf.save(`Invoice-${id}.pdf`);
		});
	};

	return (
		<>
			<Box display="block" displayPrint="none" m={1}>
				{loadPrimary && (
					<Grid container spacing={3}>
						<Grid item xs={10}></Grid>
						<Grid item xs={2}>
							{" "}
							<CustomButton label={"Print"} onButtonClick={handlePrint} />
						</Grid>
						{/* <Grid item xs={2}>
						<CustomButton label={"Download"} onButtonClick={handleDownload} />
					</Grid> */}
					</Grid>
				)}
			</Box>
			<Box displayPrint="block" m={1}>
				{/* <Card
					className={container}
					style={{ border: "1px solid" }}
				> */}
				<Grid
					container
					ref={layoutRef}
					// alignContent="center"
					// className={outerCard}
				>
					{/* <Grid container justifyContent="space-between">
						<Grid item xs={5}>
							<Typography variant="h4">{sellerName}</Typography>
						</Grid>
						<Grid item xs={1}>
							<Typography variant="h4">Invoice</Typography>
						</Grid>
					</Grid> */}
					{/* <Grid container justifyContent="space-between">
						<Grid item xs={3}>
							<Typography variant="h6">{address}</Typography>
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid> */}
					{/* <Grid item xs={12} className={dividerContainer}> */}
					{/* <Divider /> */}
					{/* </Grid> */}
					<Grid container xs={5} style={{ border: "1px solid" }}>
						<Grid
							item
							xs={12}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_from?.name}</b>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_from?.city}</b>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_from?.state}</b>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>Ph NO.- {invoiceData?.invoice_from?.contact}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>GSTIN NO.-</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_from?.gstin}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>PAN NO.-</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.acc_details?.pan}</b>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>VENDOR CODE - {invoiceData?.invoice_from?.vendorcode}</b>
						</Grid>
					</Grid>
					<Grid
						container
						xs={2}
						style={{
							display: "grid",
							border: "1px solid",
							borderTop: "2px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
					></Grid>
					<Grid container xs={5} style={{ border: "1px solid" }}>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>Invoice no.:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_no}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>Invoice Date:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_date}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>Invoice Type:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.type}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>State:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.state}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>State Code:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.stateCode}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>Project Zone:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.pzone}</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>HSN/SAC:</b>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.hsncode}</b>
						</Grid>
						{/* <Grid
							item
							xs={12}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px"
							}}
						>
							Status:
							<b>{invoiceData?.status}</b>
						</Grid> */}
					</Grid>
					{/* <Grid container justifyContent="space-between"> */}
					<Grid
						item
						xs={12}
						style={{
							display: "grid",
							border: "1px solid",
						}}
					></Grid>
					{/* </Grid> */}
					<Grid container justifyContent="space-between">
						<Grid
							item
							xs={5}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<Typography variant="h6" align="center">
								<b>Details of Receiver(Bill To)</b>
							</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						></Grid>
						<Grid
							item
							xs={5}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<Typography variant="h6" align="center">
								<b>Details of consignee(Ship To)</b>{" "}
							</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
							// justifyContent="center"
							alignItems="center"
						>
							<b>ADDRESS-</b>
						</Grid>
						<Grid
							item
							xs={3}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_to?.name}</b>
							<b>{invoiceData?.invoice_to?.city}</b>
							<b>{invoiceData?.invoice_to?.state}</b>
							{/* GSTIN:
							<b>{invoiceData?.invoice_to.gstin}</b> */}
						</Grid>
						<Grid
							item
							xs={2}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						></Grid>
						<Grid
							item
							xs={2}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
							// justifyContent="center"
							alignItems="center"
						>
							<b>ADDRESS-</b>
						</Grid>
						<Grid
							item
							xs={3}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.shipping_details?.name}</b>
							<b>{invoiceData?.shipping_details?.city}</b>
							<b>{invoiceData?.shipping_details?.state}</b>
							{/* GSTIN:
							<b>{invoiceData?.shipping_details.gstin}</b> */}
						</Grid>
						<Grid
							item
							xs={1}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>GSTIN-</b>
						</Grid>
						<Grid
							item
							xs={4}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.invoice_to?.gstin}</b>
						</Grid>
						<Grid
							item
							xs={2}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						></Grid>
						<Grid
							item
							xs={1}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>GSTIN-</b>
						</Grid>
						<Grid
							item
							xs={4}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
								padding: "6px 6px",
							}}
						>
							<b>{invoiceData?.shipping_details?.gstin}</b>
						</Grid>
					</Grid>

					<Grid xs={12} style={{ border: "1px solid" }}>
						<DisplayTable columns={columns} rows={rows} />
					</Grid>
					<Grid
						item
						xs={9}
						style={{
							display: "grid",
							border: "0.5px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
						justifyContent="center"
					>
						{/* {invoiceData?.invoice_to?.state === "Assam" ? (
							<>
								<b variant="h6">
									<b>Add SGST:</b> {gst / 2}
								</b>

								<b variant="h6">
									<b>Add CGST:</b> {gst / 2}
								</b>
							</>
						) : ( */}
						<>
							{/* <b variant="h6"> */}
							Add IGST(18%):
							{/* </b> */}
						</>
						{/* )}{" "} */}
					</Grid>
					<Grid
						item
						xs={3}
						style={{
							display: "grid",
							border: "0.5px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
						justifyContent="center"
					>
						{gst}
					</Grid>

					<Grid
						item
						xs={7}
						className={paddedGrid}
						style={{
							display: "grid",
							border: "1px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
						justifyContent="center"
					>
						Tax on Reverse Charge: NIL
						<br />
						Our GSTIN: {invoiceData?.invoice_from?.gstin}
						<br />
						PAN: {invoiceData?.acc_details?.pan}
						<br />
						Account Details <br />
						{invoiceData?.acc_details?.account_holder}
						<br />
						{invoiceData?.acc_details?.bank_name}
						{","}
						{invoiceData?.acc_details?.branch}
						<br />
						IFS Code: {invoiceData?.acc_details?.ifsc}
						<br />
						A/c No.: {invoiceData?.acc_details?.acc_no}
					</Grid>
					<Grid
						item
						xs={5}
						className={paddedGrid}
						justifyContent="center"
						style={{
							display: "grid",
							border: "1px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
					>
						{/* <Grid item xs={12}>
							<b variant="h6">
								<b>Sub-total:</b> {subtotal}
							</b>
						</Grid>
						<Grid item xs={12}>
							{invoiceData?.invoice_to?.state === "Assam" ? (
								<>
									<b variant="h6">
										<b>SGST:</b> {gst / 2}
									</b>

									<b variant="h6">
										<b>CGST:</b> {gst / 2}
									</b>
								</>
							) : (
								<>
									<b variant="h6">
										<b>IGST:</b> {gst}
									</b>
								</>
							)} */}
						{/* </Grid> */}
						{/* <Grid item xs={12}>
							<b variant="h5">
								<b>INR {total} </b>
							</b>
						</Grid>
						<Grid item xs={12}>
							<b>
								<b>(Rupees. {invoiceData?.total_amount_in_words} only. )</b>
							</b>
						</Grid> */}
					</Grid>
					<Grid
						item
						xs={9}
						style={{
							display: "grid",
							border: "1px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
						justifyContent="center"
					>
						<b>Total</b>
					</Grid>
					<Grid
						item
						xs={3}
						style={{
							display: "grid",
							border: "1px solid",
							paddingLeft: "6px",
							padding: "6px 6px",
						}}
						justifyContent="center"
					>
						<b>{total}</b>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					(Rupees. In Words : {invoiceData?.total_amount_in_words} only )
				</Grid>
				{/* </Card> */}
			</Box>
		</>
	);
};

InvoicePrintable.propTypes = {
	image: PropTypes.string,
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string.isRequired,
	menuItems: PropTypes.shape([
		{
			value: PropTypes.string,
			onClick: PropTypes.func,
		},
	]),
};

InvoicePrintable.defaultProps = {
	image: "",
	menuItems: [],
};

export default InvoicePrintable;
