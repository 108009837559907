export default class DCTableIM {
	constructor(data) {
		this.id = data.id;
		this.location = data.location;
		this.name = data.name;
		this.date = data.date;
		this.quantity = data.quantity;
		this.amount = data.amount;
		this.file = data.file_link

	}
}
