import Popup from "../../components/Popup";
import SimpleDropdown from "../../components/SimpleDropdown";
import { Grid, TextField, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { addUser, updateUser } from "../../apis/user.apis";

export default function ({ handleClose, isEditable, editableData }) {
	const { values, handleSubmit, handleChange, handleBlur } = useFormik({
		initialValues: {
			first_name: isEditable ? editableData.first_name : "",
			last_name: isEditable ? editableData.last_name : "",
			username: isEditable ? editableData.username : "",
			email: isEditable ? editableData.email : "",
			phone: isEditable ? editableData.phone : "",
			role: isEditable ? editableData.role : "",
			account_number: isEditable ? editableData.account_number : "",
			account_holder_name: isEditable ? editableData.account_holder_name : "",
			bank_name: isEditable ? editableData.bank_name : "",
			salary: isEditable ? editableData.salary : "",
			ifsc_code: isEditable ? editableData.ifsc_code : "",
			password: "password",
		},
		onSubmit(values) {
			isEditable
				? updateUser(editableData.id, values)
						.then((res) => {
							console.log(res);
							
							handleClose();
						})
						.catch((err) => console.log({ err }))
				: addUser(values)
						.then((res) => {
							console.log(res);
							handleClose();
						})
						.catch((err) => console.log(err));
		},
	});

	return (
		<Popup
			name="Add Team Member"
			open={true}
			handleClose={handleClose}
			maxWidth="sm"
		>
			<form
				style={{
					padding: "0 25%",
					paddingBottom: "25px",
					width: "97%",
				}}
				onSubmit={handleSubmit}
			>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField
							id="first_name"
							variant="standard"
							label="First Name"
							name="first_name"
							fullWidth
							value={values.first_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="last_name"
							variant="standard"
							label="Last Name"
							name="last_name"
							fullWidth
							value={values.last_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="phone"
							variant="standard"
							label="Contact Number"
							name="phone"
							type="phone"
							fullWidth
							value={values.phone}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="username"
							variant="standard"
							label="Username"
							name="username"
							fullWidth
							value={values.username}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="role"
							variant="standard"
							label="Role"
							name="role"
							type="role"
							fullWidth
							value={values.role}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="salary"
							variant="standard"
							label="Salary"
							name="salary"
							type="salary"
							fullWidth
							value={values.salary}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="email"
							variant="standard"
							label="Email ID"
							name="email"
							type="email"
							fullWidth
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="account_holder_name"
							variant="standard"
							label="Account Holder"
							name="account_holder_name"
							fullWidth
							value={values.account_holder_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="account_number"
							variant="standard"
							label="Account Number"
							name="account_number"
							fullWidth
							value={values.account_number}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="bank_name"
							variant="standard"
							label="Branch Name"
							name="bank_name"
							fullWidth
							value={values.bank_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="ifsc_code"
							variant="standard"
							label="ifsc_code Code"
							name="ifsc_code"
							fullWidth
							value={values.ifsc_code}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "5%",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							size="large"
							style={{ height: "100%", width: "50%" }}
						>
							{isEditable ? "Update Team Member" : "Add Team Member"}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Popup>
	);
}
