import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LogoIcon from "../../icons/svgs/techvariable_icon.svg";
import useStyles from "./style";
import {
	Button,
	Checkbox,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

import theme from "../../theme";
import { useBasicNav } from "../../utils/useBasicNav";
import { useFormik } from "formik";
import { login } from "../../apis/user.apis";
import {
	resetPersistentAuthentication,
	setPersistentAuthentication,
} from "../../utils/functions";
import { authIcon } from "../../assets/images";
import CustomButton from "../../components/buttons/CustomButton/CustomButton";
import { Link, useHistory } from "react-router-dom";
import TextLogo from "../../components/TextLogo";

const LoginPage = ({ showToast, hideToast, loadPrimary }) => {
	
	const {
		logoText,
		logo,
		imageContainer,
		imageStyle,
		formContainer,
		subTitleText,
		textCentered,
		linkStyle,
		loginContainer,
		contentContainer,
	} = useStyles();

	const { setActiveTab } = useBasicNav();
	const [rememberMe, setRememberMe] = useState(false);
	const history = useHistory();
	useEffect(() => {
		// resetPersistentAuthentication();
		loadPrimary(false);
	}, []);
	console.log({ rememberMe });

	const { values, errors, handleChange, handleSubmit } = useFormik({
		initialValues: {
			username: "",
			password: "",
		},
		onSubmit: (values) => {
			login(values)
				.then((response) => {
					console.log({ response });
					// if (response.status != 200) return showToast.error('');
					if (rememberMe) {
						// localStorage.setItem({ token: response?.token });
						setPersistentAuthentication({
							token: response?.token,
							rememberMe: true,
							user: values?.username,
						});
					} else {
						setPersistentAuthentication({
							token: response?.token,
							user: values?.username,
						});
					}
					showToast.success(response.message);
					values.username === "rana@admin"
						? setActiveTab("dashboard")
						: setActiveTab("delivery");
				})
				.catch((err) => {
					showToast.error(err.message);
				});
		},
	});

	const handleChecked = (e) => {
		if (e.target.checked) {
			setRememberMe(true);
		} else {
			setRememberMe(false);
		}
	};

	const handleForgotPassword = (event) => {
		event.preventDefault();

		history.push("forgot-password");
	};
	// const submit = (e) => e.key == "Enter" && handleSubmit();

	return (
		<Grid container justify="center" className={contentContainer}>
			<Grid item xs={6} className={imageContainer}>
				<img src={authIcon} className={imageStyle} />
			</Grid>
			<Grid item xs={6} className={loginContainer}>
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					className={formContainer}
					spacing={3}
				>
					<Grid item xs={12}>
						<TextLogo variant={"h4"} />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="subtitle" className={subTitleText}>
							Welcome back! Please login to your account.
						</Typography>
					</Grid>
					<Grid item xs={10} md={6}>
						<Grid container spacing={5}>
							<Grid item xs={12}>
								<TextField
									name="username"
									fullWidth
									value={values.username}
									onChange={handleChange}
									placeholder="Username"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									name="password"
									type="password"
									fullWidth
									value={values.password}
									onChange={handleChange}
									placeholder="Password"
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={1}>
										<Checkbox onChange={handleChecked} />
									</Grid>
									<Grid item xs={4} className={textCentered}>
										<Typography>Remember Me</Typography>
									</Grid>
									<Grid item xs={3} />
									<Grid item xs={4} className={textCentered}>
										<Typography>
											{" "}
											<Link
												className={linkStyle}
												onClick={handleForgotPassword}
											>
												Forgot Password
											</Link>
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid container justifyContent="space-around">
								<Grid item xs={6}>
									<CustomButton label={"Login"} onButtonClick={handleSubmit} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

LoginPage.propTypes = {
	// As discussed, will be decided later
};

export default LoginPage;
