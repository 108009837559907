import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles({
	tableHeader: {
		backgroundColor: "#F5F6FA",
		minHeight: theme.typography.pxToRem(45),
		alignItems: "center",
		padding: theme.typography.pxToRem(10),
	},
	tableCard: {
		padding: theme.typography.pxToRem(20),
	},
	productLayout: {
		margin: theme.typography.pxToRem(15),
	},
	content: {
		paddingTop: theme.typography.pxToRem(20),
		// paddingTop: theme.typography.pxToRem(40),
		paddingBottom: theme.typography.pxToRem(5),
		paddingLeft: theme.typography.pxToRem(150),
		paddingRight: theme.typography.pxToRem(50),
		backgroundColor: "#F0F0F7",
	},
	cardStyle: {
		width: "100%",
		padding: theme.typography.pxToRem(20),
	},
	starIcon: {
		display: "flex",
	},
	divider: {
		marginTop: theme.typography.pxToRem(10),
	},
	chartCard: {
		padding: theme.typography.pxToRem(30),
	},
	chartContainer: {
		marginTop: "30px",
		padding: "30px",
		minHeight: "392px",
	},
	typographyStyle: {
		color: "#868592",
		margin: "15px 0 0",
	},
	formContainer: {
		padding: "20px 10% ",
	},
	productContainer: {
		backgroundColor: "#fafafa",
		padding: "20px 0",
	},
	totalContainer: {
		backgroundColor: "#f3f3f4",
		padding: "20px 10%",
	},
	totalText: {
		color: "#868592",
		fontSize: "0.9rem",
	},
	invoiceCard: {
		minHeight: "60px",
		paddingTop: "10px",
		paddingBottom: "10px",
		paddingLeft: "20px",
		cursor: "pointer",
	},
	chipStyleRed: {
		minWidth: "80px",
		borderColor: "#f1556c",
	},
	chipStyleGreen: {
		minWidth: "80px",
		borderColor: "#1abc9c",
	},
	chipGrid: {
		padding: "10px 0px",
		alignSelf: "center",
	},
});

export default useStyles;
