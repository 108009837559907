import { makeStyles } from "@material-ui/core";
import theme from "../../../theme";

const drawerWidth = 260;

export const useStyles = makeStyles({
	navItems: {
		lineHeight: theme.typography.pxToRem(14),
	},
	navItem: {
		display: "flex",
		padding: theme.typography.pxToRem(15),
		borderLeftWidth: theme.typography.pxToRem(7),
		borderLeftColor: theme.palette.standard.main,
		borderLeftStyle: "solid",
		cursor: "pointer",
		"& div": {
			color: "#DEF0F2",
		},
	},
	navActive: {
		borderLeftWidth: theme.typography.pxToRem(7),
		borderLeftColor: "#7f7cc0",
		borderLeftStyle: "solid",
		background: "#3c3b54",
	},
	drawerOpen: {
		width: drawerWidth,
		background: theme.palette.standard.main,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		background: theme.palette.standard.main,
		overflowX: "hidden",
		width: theme.spacing(5) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(8) + 1,
		},
	},

	menuButton: {
		marginRight: theme.spacing(1),
		color: "white",
	},
	hide: {
		display: "none",
	},
	drawer: {
		flexShrink: 0,
		// background: theme.palette.standard.main,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		background: "#3c3b54",
		minHeight: theme.typography.pxToRem(70),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
});
