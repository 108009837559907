import Popup from "../../components/Popup";
import SimpleDropdown from "../../components/SimpleDropdown";
import {
	Grid,
	TextField,
	Button,
	Typography,
	IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import { addItems, getAllItems } from "../../apis/inventory.apis";
import { useEffect, useState } from "react";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import useStyles from "./styles";

/**
 *
 * @param {Object} param
 * @param {(props : any) => void} param.handleClose
 * @param {('Return' | 'Replacement')} param.orderType
 * @param {string} param.title
 * @returns
 */
export default function ({ handleClose, title }) {
	const initValues = {
		title: "",
		barcode: "",
		price: 0,
		description: "",
		fundamental_unit: "",
		total_stock: 0,
	};
	const { addItems } = useStyles();
	const [itemsAdded, setItemsAdded] = useState(1);

	const [itemList, setItemList] = useState();

	const { values, handleSubmit, handleBlur, handleChange, setFieldValue } =
		useFormik({
			initialValues: initValues,
			onSubmit: (values) => {
				addItems(values)
					.then((res) => {
						console.log(res);
						handleClose();
					})
					.catch((err) => {
						console.log(err);
						handleClose();
					});
			},
		});

	useEffect(async () => {
		const items = await getAllItems();
		setItemList(items.data);
	}, []);

	const handleAdd = () => {
		setItemsAdded(itemsAdded + 1);
	};
	return (
		<Popup name={title} open={true} handleClose={handleClose} maxWidth="sm">
			<form
				style={{
					padding: "2% 10%",
					paddingBottom: "25px",
				}}
				onSubmit={handleSubmit}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							id="title"
							variant="outlined"
							label="Title"
							name="title"
							fullWidth
							value={values.title}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="barcode"
							variant="outlined"
							label="Barcode"
							name="barcode"
							fullWidth
							value={values.barcode}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="price"
							variant="outlined"
							label="Price"
							name="price"
							type="number"
							fullWidth
							value={values.price}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="description2"
							variant="outlined"
							label="Description"
							name="description"
							fullWidth
							value={values.description}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<SimpleDropdown
							variant="outlined"
							id="fundamental_unit"
							label="Fundamental Unit"
							onChange={(e) => {
								setFieldValue("fundamental_unit", e.target.value);
							}}
							menuItem={["Bags"]}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="total_stock"
							variant="outlined"
							label="Total Stock"
							name="total_stock"
							type="number"
							fullWidth
							value={values.total_stock}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={11} className={addItems}>
						<Typography variant="h5">Add Items</Typography>
					</Grid>
					<Grid item xs={1}>
						<IconButton onClick={handleAdd}>
							<PlaylistAddIcon fontSize="large" />
						</IconButton>
					</Grid>
					<Grid item xs={6}>
						<SimpleDropdown
							variant="outlined"
							id="item"
							label="Item"
							onChange={(e) => {
								setFieldValue("item", e.target.value);
							}}
							menuItem={itemList}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="quantity"
							variant="outlined"
							label="Quantity"
							name="quantity"
							type="number"
							fullWidth
							value={values.quantity}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							size="large"
						>
							Add Product Details
						</Button>
					</Grid>
				</Grid>
			</form>
		</Popup>
	);
}
