import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import Styles from "./style";

const useStyles = makeStyles(Styles);

const SearchField = ({
  label,
  data,
  onSelect,
  disable,
  onChange,
  textField,
  isLoading,
  isAutofocused,
  onSearch
}) => {
  const { rootStyle, outlinedRootStyle, adornmentStyle,noBorder} = useStyles();

  const [value, setValue] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [hintText, setHintText] = useState("");

  useEffect(() => {
    if (!data || !data.length) {
      if (!currentValue || (currentValue && currentValue.length < 3)) {
        setHintText("Enter 3 or more characters!");
      } else if (isLoading) setHintText("Loading...");
      else setHintText("No Catalog Found!");
    } else {
      setHintText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentValue]);

  const handleSelect = (event, val) => {
    setValue(val);
    
  };
  
  
  const handleHit = (currValue) => {
    setCurrentValue(currValue);
    onSearch(currValue)

    if (onChange && (currValue.length === 0 || currValue.length > 2)) {
      onChange(currValue);
    }
  };

  const handleDebounce = useCallback(debounce(handleHit, 400), []);

  const handleChange = (event) => {
    handleDebounce(event.target.value);
  };

  useEffect(() => {
    if (value !== undefined && onSelect) onSelect(value);
  }, [onSelect, value]);

  return (
    <Autocomplete
      options={data} // data is an Array
      filterOptions={(options) => options}
      getOptionLabel={(option) => (textField ? option[textField] : option)}
      onChange={handleSelect}
      forcePopupIcon={false}
      autoComplete
      clearOnBlur={false}
      noOptionsText={hintText}
      className={rootStyle}
      disabled={disable}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          autoFocus={isAutofocused}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: outlinedRootStyle,
              notchedOutline:noBorder
            },
            startAdornment: (
              <InputAdornment position="start" className={adornmentStyle}>
                <SearchIcon />
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

SearchField.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  textField: PropTypes.string,
  onSelect: PropTypes.func,
  disable: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isAutofocused: PropTypes.bool,
  onSearch: PropTypes.func,
};

SearchField.defaultProps = {
  label: "",
  data: [],
  textField: undefined,
  onSelect: undefined,
  disable: false,
  onChange: undefined,
  isLoading: undefined,
  isAutofocused: false,
};

export default SearchField;
