import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { getStateDetails } from "../../apis/extra.apis";

const InvoiceForm = ({ handleInvoiceDetail }) => {
	const { typographyStyle, formContainer } = useStyles();
	const [invoiceFrom, setInvoiceFrom] = useState([]);

	const handleInvoiceFrom = (e) => {
		const { name, value } = e.target;
		setInvoiceFrom({ ...invoiceFrom, [name]: value });
	};

	useEffect(() => {
		handleInvoiceDetail({
			...invoiceFrom,
		});
	}, [invoiceFrom]);

	return (
		<form>
			<Grid container spacing={3} className={formContainer}>
				<Grid item xs={12}>
					<Typography variant="h6"> Challan Details</Typography>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Name</Typography>
					<TextField
						name="name"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Date</Typography>
					<TextField
						name="date"
						type="date"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Location</Typography>
					<TextField
						name="location"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>

				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>D.C. No.</Typography>
					<TextField
						name="dc_no"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Quantity</Typography>
					<TextField
						name="quantity"
						type="number"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>
						Mode of Transportation
					</Typography>
					<TextField
						name="mode_of_transport"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Weight</Typography>
					<TextField
						name="weight"
						type="number"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>

				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Amount</Typography>
					<TextField
						name="amount"
						type="number"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
						type="number"
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>File Link</Typography>
					<TextField
						name="file_link"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

InvoiceForm.propTypes = {};

export default InvoiceForm;
