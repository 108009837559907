import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	squareStyle: {
		borderRadius: theme.typography.pxToRem(5),
	},
	root: {
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		minHeight: theme.typography.pxToRem(36),
	},
	tableChipStyle: {
		marginRight: theme.typography.pxToRem(20),
		marginTop: theme.typography.pxToRem(10),
		color: "black",
	},
}));

export default useStyles;
