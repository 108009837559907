import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

export const useStyles = makeStyles({
	container: {
		width: "100%",
		display: "flex",
		marginTop: theme.typography.pxToRem(80),
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	childs: {
		// marginLeft: theme.typography.pxToRem(116),
		width: `calc(100vw - ${theme.typography.pxToRem(116)})`,
		flexGrow: 1,
		padding: theme.spacing(3),
	},
});
