import React, { useEffect, useRef, useState } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BasicLayout from "../../components/BasicLayout";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import InvoiceForm from "./InvoiceForm";
import CustomButton from "../../components/buttons/CustomButton";
import { useHistory, useParams } from "react-router";
import { generateInvoice } from "../../apis/invoice.apis";
import HistoryIcon from "@mui/icons-material/History";
import sampleActivity from "../../utils/SampleActivity.xlsx";
import { generateBillBulk, generateBillSingle } from "../../apis/bills.apis";
import { useBasicNav } from "../../utils/useBasicNav";
import { generateActivity, generateActivityBulk } from "../../apis/activity.apis";

const ActivityPage = ({ loadPrimary, showToast }) => {
	useBasicNav("activity");
	const { content, productContainer, totalContainer, totalText } = useStyles();
	const [data, setData] = useState({});
	const { type } = useParams();
	const hiddenFileInput = useRef();

	// const user = useRecoilValue(currentUser);
	const notifications = useRecoilValue(userNotification);
	const history = useHistory();

	useEffect(() => {
		loadPrimary(false);
	}, []);

	const handleSave = () => {
		generateActivity(data)
			.then((res) => showToast.success("Bill saved Successfully"))
			.catch((err) => console.log(err));
	};

	console.log({ data });

	const handleData = (payload) => {
		console.log({ payload });
		setData({ ...data, ...payload });
	};

	const handleDownloadCsv = () => {
		window.location.href = sampleActivity;
	};

	const handleBulkUpload = () => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		console.log("in handleChange");
		const fileUploaded = event.target.files[0];
		console.log({ fileUploaded });
		let data = new FormData();
		data.append("file", fileUploaded);
		console.log({ data });
		generateActivityBulk(data)
			.then((res) => showToast.success("File Uploaded Successfully"))
			.catch((err) => showToast.error("File couldn't be uploaded"));
	};

	const handleHistory = () => {
		history.push("/app/activity/history");
	};

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h4">Create Activity</Typography>
				</Grid>
				<Grid item xs={2}>
					<CustomButton
						label={"Download Template"}
						onButtonClick={handleDownloadCsv}
					/>
				</Grid>
				<Grid item xs={2}>
					<CustomButton
						label={"Bulk Upload"}
						onButtonClick={handleBulkUpload}
					/>
					<input
						type="file"
						ref={hiddenFileInput}
						onChange={handleChange}
						style={{ display: "none" }}
					/>
				</Grid>
				<Grid item xs={2}>
					<CustomButton label={"History"} onButtonClick={handleHistory} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<InvoiceForm handleInvoiceDetail={handleData} isABill={true} />
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className={totalContainer}>
					<Grid container spacing={3}>
						<Grid item xs={8} />
						<Grid item xs={2}>
							<CustomButton label="Save Activity" onButtonClick={handleSave} />
						</Grid>
						<Grid item xs={2}>
							<CustomButton isOutlined label="Cancel" />
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</>
	);
};

ActivityPage.propTypes = {};

export default ActivityPage;
