import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Divider, Grid, Typography } from "@material-ui/core";
import DetailCard from "../../components/cards/DetailCard/DetailCard";
import CustomButton from "../../components/buttons/CustomButton/CustomButton";
import CustomChip from "../../components/chips/CustomChip";
import StarIcon from "@material-ui/icons/Star";
import useStyles from "./styles";
import CachedIcon from "@material-ui/icons/Cached";
import QuickDetailCard from "../../components/cards/QuickDetailCard";
import BasicLayout from "../../components/BasicLayout";
import AddProductModal from "./AddProduct";
import {
	getAllDetails,
	getAllItems,
	getQuickDetails,
} from "../../apis/inventory.apis";
import {
	addIcon,
	awaiting,
	cart,
	distribution,
	empty,
	hold,
	low,
	pending,
	range,
} from "../../assets/images";
import { Info } from "@material-ui/icons";
import theme from "../../theme";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import ColumnChart from "../../components/ColumnChart";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getAllChallan } from "../../apis/dc.apis";
import TableCard from "../../components/cards/TableCard";
import { useBasicNav } from "../../utils/useBasicNav";

const InventoryPage = ({ loadPrimary }) => {
	useBasicNav("inventory");
	const {
		tableHeader,
		content,
		cardStyle,
		productLayout,
		tableCard,
		starIcon,
		divider,
		quickDetail,
	} = useStyles();
	const [items, setItems] = useState([]);
	const [otherDetails, setOtherDetails] = useState([]);
	const [quickDetails, setQuickDetails] = useState([]);
	const [distributionDetail, setDistributionDetail] = useState(false);
	const [addProductOpenModal, setAddProductOpenModal] = useState(false);
	// const [view]
	const colors = [
		"rgba(105, 228, 166, 0.2)",
		"rgba(255, 114, 133, 0.2)",
		"rgba(255, 202, 131, 0.2)",
	];
	const topCards = [distribution, cart, range];
	const detailsIcon = [pending, awaiting, hold, low, empty];
	const notifications = useRecoilValue(userNotification);

	useEffect(async () => {
		let item = await getAllChallan();
		// let { distribution_graph, ...detail } = await getAllDetails();
		// let qDetail = await getQuickDetails();
		// .then((res) => {
		// 	setDashboard(res);
		// })
		// .then((err) => console.log(err));
		// setQuickDetails(qDetail);
		// setOtherDetails(detail);
		setItems(item);
		// setDistributionDetail(distribution_graph);
		// setDashboard(response);
		loadPrimary(false);
	}, []);

	console.log("items", items);

	const handleColor = () => {
		const random = Math.floor(Math.random() * colors.length);
		return colors[random];
	};

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4">Delivery Challan Details</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						{Object.keys(otherDetails)?.map((key, index) => (
							<Grid item xs={12} md={4}>
								<DetailCard
									id={index}
									num={otherDetails[key]}
									textValue={`${key.split("_")[0]} ${key.split("_")[1]}`}
									icon={topCards[index]}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				{/* <Grid item xs={12}> */}
				{/* <Grid container spacing={3}> */}
				<Grid item xs={12} md={12} style={{ padding: "10px" }}>
					<TableCard
						header={"Delivery Challans"}
						columns={[
							"Location",
							"Date",
							"DC NO",
							"Name",
							"Quantity",
							"Weight",
							"Amount",
							"File Link",
						]}
						rows={items}
					/>
				</Grid>
				{/* <Grid item xs={12} md={8}>
							<Card>
								<Grid container spacing={3} className={tableCard}>
									<Grid item xs={9}>
										<Typography variant="h5">Delivery Challan</Typography>
									</Grid>
									<Grid item xs={3}>
										<CustomButton
											color={"Custom"}
											label={"Add New"}
											startIcon={addIcon}
											onButtonClick={() => setAddProductOpenModal(true)}
										/>
									</Grid>
									<Grid container className={tableHeader}>
										<Grid item xs={6}>
											<Typography variant="h6">PRODUCT</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="h6">AVAILABILITY</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="h6">TOTAL</Typography>
										</Grid>
									</Grid>
									{items?.map((product) => (
										<Grid
											container
											justifyContent="center"
											alignContent="center"
											className={productLayout}
											key={product.id}
										>
											<Grid item xs={6} className={starIcon}>
												<StarIcon color="error" />

												<Typography>{product.title}</Typography>
											</Grid>
											<Grid item xs={3}>
												<CustomChip
													label={`${product.availability} in Stock`}
													color={
														colors[Math.floor(Math.random() * colors.length)]
													}
												/>
											</Grid>
											<Grid item xs={3}>
												<Typography>{product.total}</Typography>
											</Grid>
										</Grid>
									))}
									{/* <Grid item xs={12} justifyContent="center">
										<Typography>Show More</Typography>
									</Grid> */}
				{/* </Grid>
							</Card>
						</Grid>  */}
				{/* <Grid item xs={12} md={4}>
							<Card>
								<Grid container spacing={3} className={tableCard}>
									<Grid item xs={7}>
										<Typography variant="h5">Quick Details</Typography>
									</Grid>
									<Grid item xs={5}>
										<CustomButton
											color={"Custom"}
											label={"View All"}
											onButtonClick={() => setAddProductOpenModal(true)}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={3}>
									<Divider variant="middle" className={divider} />

									{Object.keys(quickDetails)?.map((detail, index) => (
										<Grid item xs={12} className={quickDetail}>
											<QuickDetailCard
												logo={detailsIcon[index]}
												header={detail}
												detail={quickDetails[detail]}
											/>
											<Divider variant="middle" className={divider} />
										</Grid>
									))}
								</Grid>
							</Card>
						</Grid> */}
				{/* <Grid item xs={8}>
					<ColumnChart
						categories={otherDetails?.tasks_graph?.categories}
						dataSeries={otherDetails?.tasks_graph?.dataSeries}
					/>
				</Grid> */}
				{/* </Grid> */}
				{/* </Grid> */}
			</Grid>
			{addProductOpenModal && (
				<AddProductModal
					title={"Add New Product"}
					handleClose={() => {
						setAddProductOpenModal(false);
					}}
				/>
			)}
		</>
	);
};

InventoryPage.propTypes = {};

export default InventoryPage;
