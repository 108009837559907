import { PureComponent, Component } from "react";
import Highcharts from "highcharts";
import HighMaps from "highcharts/highmaps";
import map from "highcharts/modules/map";
import nemap from "./india-map";
import "./style.css";
import { Card, Grid, Typography, Button } from "@material-ui/core";

export default class NEMapChart extends Component {
	/**
	 *
	 * @param {Object} props
	 * @param {Object} props.StateData
	 * @param {number} props.StateData.Assam
	 * @param {number} props.StateData.Arunachal
	 * @param {number} props.StateData.Nagaland
	 * @param {number} props.StateData.Manipur
	 * @param {number} props.StateData.Mizoram
	 * @param {number} props.StateData.Tripura
	 * @param {number} props.StateData.Meghalaya
	 * @param {number} props.StateData.Sikkim
	 */
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.createMapChart();
	}

	createMapChart() {
		const { StateData } = this.props;
		console.log({StateData})
		const data = [
			{
				"hc-key": "in-sk",
				value: StateData.Sikkim,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-nl",
				value: StateData.Nagaland,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-mn",
				value: StateData.Manipur,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-ar",
				value: StateData.Arunachal,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-mz",
				value: StateData.Mizoram,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-tr",
				value: StateData.Tripura,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-as",
				value: StateData.Assam,
				dataLabels: "{point.value} units",
			},
			{
				"hc-key": "in-ml",
				value: StateData.Meghalaya,
				dataLabels: "{point.value} units",
			},
		];
		HighMaps.mapChart("nemap-container", {
			title: {
				text: "",
			},

			subtitle: {
				text: "",
			},

			colorAxis: {
				visible: false,
				min: 0,
				minColor: "#dadada",
				maxColor: "#dadada",
			},

			tooltip: {
				headerFormat: "",
				pointFormat: "<b>{point.name}</b><br> {point.value} units sent",
			},

			series: [
				{
					mapData: nemap,
					data: data,
					name: "North-East India",
					cursor: "pointer",
					states: {
						hover: {
							color: "#6180C0",
						},
					},
					allAreas: true,
					dataLabels: {
						enabled: true,
						format: "•",
						style: {
							fontSize: "2rem",
						},
					},
				},
			],
		});
	}

	render() {
		return (
			<Card className="chart-container">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h5">NE Challan Distribution Map</Typography>
						<div className="spaceBWDiv">
							<Button variant="text" size="large">
								Show all list
							</Button>
						</div>
					</Grid>
				</Grid>
				<div id="nemap-container" className="nemap-container"></div>
			</Card>
		);
	}
}
