import brandImg from "./tv-logo.svg";
import linechart from "./linechart.svg";
import addTab from "./addTabIcon.svg";
import Line from "./Line.svg";
import LineChart from "./BarLine.svg";
import lineX from "./lineX.svg";
import pi from "./pi.svg";
import piChart from "./piChart.svg";
import polation from "./polation.svg";
import squareGraph from "./squareGraph.svg";
import curves from "./curves.svg";
import circles from "./circles.svg";
import ChartBarX from "./chartBarX.svg";
import map from "./map.svg";
import candle from "./candle.svg";
import simpleBar from "./SimpleBar.svg";
import graphIc from "./graphIcon.svg";
import save from "./save.svg";
import add from "./add.svg";
import noDash from "./noDash.png";
import noGraph from "./noGraph.svg";
import authImage from "./authImage.svg";
import whatsapp from "./whatsapp.png";
import barChartBlue from "./barChartBlue.png";
import barChartGreen from "./barChartGreen.jpg";
import barChartPurple from "./barChartPurple.png";
import chatIcon from "./icon_chat.png";
import customersIcon from "./icon_customers.png";
import dashboardIcon from "./icon_dashboard.png";
import helpIcon from "./icon_help.png";
import invoiceIcon from "./icon_invoice.png";
import processIcon from "./icon_process.png";
import productsIcon from "./icon_products.png";
import settingIcon from "./icon_setting.png";
import dressIcon from "./dress.jpg";
import rangeIcon from "./range.png";
import cartIcon from "./cart.png";
import REVERSE from "./REVERSE.png";
import LOW from "./LOW.png";
import NOSTOCK from "./NOSTOCK.png";
import ADD from "./ADD.png";
import HOLD from "./HOLD.png";

export const pending = ADD;
export const low = LOW;
export const awaiting = REVERSE;
export const empty = NOSTOCK;
export const hold = HOLD;
export const cart = cartIcon;
export const distribution = dressIcon;
export const range = rangeIcon;
export const chat = chatIcon;
export const customers = customersIcon;
export const dashboard = dashboardIcon;
export const help = helpIcon;
export const invoice = invoiceIcon;
export const process = processIcon;
export const products = productsIcon;
export const setting = settingIcon;

export const BrandImageIcon = brandImg;

export const LineChartSvg = linechart;
export const addTabIcon = addTab;

export const LineIcon = Line;
export const LineChartIcon = LineChart;
export const lineXIcon = lineX;
export const piIcon = pi;
export const piChartIcon = piChart;
export const polationIcon = polation;
export const squareGraphIcon = squareGraph;
export const curvesIcon = curves;
export const circlesIcon = circles;
export const ChartBarXIcon = ChartBarX;
export const mapIcon = map;
export const candleIcon = candle;
export const simpleBarIcon = simpleBar;

export const graphIcon = graphIc;
export const saveIcon = save;
export const addIcon = add;
export const noDashIcon = noDash;
export const noGraphIcon = noGraph;
export const authIcon = authImage;
export const barBlue = barChartBlue;
export const barGreen = barChartGreen;
export const barPurple = barChartPurple;

export const whatsappIcon = whatsapp;

// export const CHARTS_IMAGE = {
//   [GRAPHKEYS.BAR.key]: lineX,
//   [GRAPHKEYS.COLUMN.key]: ChartBarX,
//   [GRAPHKEYS.LINE.key]: Line,
//   [GRAPHKEYS.SCATTER.key]: polation,
//   [GRAPHKEYS.PIE.key]: piChart,
//   [GRAPHKEYS.DONUT.key]: pi,
//   [GRAPHKEYS.GEO.key]: map,
//   [GRAPHKEYS.AREA.key]: curves,
//   [GRAPHKEYS.PACKED_BUBBLE.key]: circles,
// };
