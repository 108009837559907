import { PERSISTENT_AUTHENTICATION } from "./constants";

/**
 * @function getPath
 * @description Constructs the absolute path for the routes in the application
 * If the basePath property of the routeConfig is set, it will be
 * appended to the path value of each route
 * @param {string} path  -   The route path
 * @param {string} basePath -The basePath of the routeConfig
 * @returns
 */
export const getPath = (path, basePath) => {
	if (!basePath) return path;
	return `${basePath}${path}`;
};

//#region QueryObject
/**
 * @class QueryObject
 * @description A construction function which helps in generating a queryObject which can be passed to the @see {@link getQueryString}}
 * or getting the queryString itself using the QueryObject's getString method
 */


function createQueryString(queryString) {
	return "?" + new URLSearchParams(queryString).toString();
}

export function QueryObject() {
	this.queryObject = [];
}
/**
 * @method add
 * @memberof QueryObject
 * @description Add an queryObject
 * @param {string} name - the key of the object
 * @param {*} value - the value of the object
 * @returns
 */
QueryObject.prototype.add = function (name, value) {
	if (name) {
		this.queryObject.push({ name, value });
	}
	return this;
};



/**
 * @memberof QueryObject
 * @method get
 * @description Get the constructed query object
 * @example [{name : "search",value : "A big brown fox"}]
 * @returns
 */
QueryObject.prototype.get = function () {
	return this.queryObject;
};
/**
 * @memberof QueryObject
 * @method getString
 * @description Get the queryString version of the constructed queryObject
 * @example ?search=A%20big%20brown%20fox
 * @returns
 */
QueryObject.prototype.getString = function () {
	let queryString = {};
	let nonEmptyQuery = this.queryObject.filter(
		(item) => item.value || item.value === 0
	);
	for (let i = 0; i < nonEmptyQuery.length; i++) {
		let queryParam = nonEmptyQuery[i];
		queryString[queryParam.name] = queryParam.value;
	}
	return "?" + createQueryString(queryString);
};
//#endregion

/**
 * @function setPersistentAuthentication
 * @description Set the provided data in the session storage as persistent data
 * @param {*} data - the data to store
 * @returns
 */
export const setPersistentAuthentication = (data) =>
	// console.log({data})
	localStorage.setItem(
		PERSISTENT_AUTHENTICATION,
		JSON.stringify({
			...data,
		})
	);

/**
 * @function getPersistentAuthentication
 * @description get the persisted data
 * @returns
 */
export const getPersistentAuthentication = () => {
	let state = localStorage.getItem(PERSISTENT_AUTHENTICATION);
	return JSON.parse(state);
};

/**
 * @function resetPersistentAuthentication
 * @description resets the persistent store
 * @returns
 */
export const resetPersistentAuthentication = () =>
	localStorage.setItem(
		PERSISTENT_AUTHENTICATION,
		JSON.stringify({
			token: "",
		})
	);

export const isEmpty = (obj) => {
	// null and undefined are "empty"
	if (obj == null) return true;

	// Assume if it has a length property with a non-zero value
	// that that property is correct.
	if (obj.length > 0) return false;
	if (obj.length === 0) return true;

	// If it isn't an object at this point
	// it is empty, but it can't be anything *but* empty
	// Is it empty?  Depends on your application.
	if (typeof obj !== "object") return true;

	// Otherwise, does it have any properties of its own?
	// Note that this doesn't handle
	// toString and valueOf enumeration bugs in IE < 9
	for (var key in obj) {
		if (hasOwnProperty.call(obj, key)) return false;
	}

	return true;
};
