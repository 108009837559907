import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid, MenuItem, TextField, Typography } from "@material-ui/core";

import useStyles from "./styles";
import { getStateDetails } from "../../apis/extra.apis";
import { generateChallanBulk } from "../../apis/dc.apis";

const InvoiceForm = ({ handleInvoiceDetail, isABill }) => {
	const { typographyStyle, formContainer } = useStyles();
	const [invoiceFrom, setInvoiceFrom] = useState([]);
	const [stateDetails, setStateDetails] = useState([]);

	const handleInvoiceFrom = (e) => {
		const { name, value } = e.target;
		setInvoiceFrom({ ...invoiceFrom, [name]: value });
	};

	useEffect(() => {
		handleInvoiceDetail({
			...invoiceFrom,
		});
	}, [invoiceFrom]);

	useEffect(() => {
		getStateDetails()
			.then((res) => setStateDetails(res.data))
			.catch((err) => console.log(err));
	}, []);

	return (
		<form>
			<Grid container spacing={3} className={formContainer}>
				<Grid item xs={12}>
					{isABill ? (
						<Typography variant="h6"> Bill Details</Typography>
					) : (
						<Typography variant="h6"> Invoice Details</Typography>
					)}
				</Grid>
				{/* <Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Invoice No.</Typography>
					<TextField fullWidth variant="outlined" size="small" />
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Dated</Typography>
					<TextField type="date" fullWidth variant="outlined" size="small" />
				</Grid> */}
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Bill No.</Typography>
					<TextField
						name="bill_no"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Date</Typography>
					<TextField
						type="date"
						name="date"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				{/* <Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>State</Typography>
					<TextField
						name="state"
						fullWidth
						select
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					>
						{Object.keys(stateDetails).map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				</Grid> */}
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Amount</Typography>
					<TextField
						name="amount"
						fullWidth
						type="number"
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Location</Typography>
					<TextField
						name="location"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>File Link</Typography>
					<TextField
						name="file_link"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

InvoiceForm.propTypes = {};

export default InvoiceForm;
