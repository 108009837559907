import axios from "./axios.global";


export const getAllDetails = async () => {
	try {
		let response = await axios.get("/inventory/details/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllItems = async () => {
	try {
		let response = await axios.get("/inventory/products/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const addItems = async (payload) => {
	try {
		let response = await axios.post("/inventory/products/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getTasks = async () => {
	try {
		let response = await axios.get("/inventory/tasks/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getQuickDetails = async () => {
	try {
		let response = await axios.get("/inventory/details/quick");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
