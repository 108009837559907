export default {
  convert: (num) => {
    if (num >= 1000000000) {
      return `$${(num / 1000000000).toFixed(2)}B`;
    }
    if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(2)}M`;
    }
    if (num >= 100000) {
      return `$${(num / 100000).toFixed(2)}HT`;
    }
    return `$${(num / 1000).toFixed(2)}T`;
  },
  roundOff: (value, decimals) => Number(value).toFixed(decimals),
  usFormat: (value) => Number(value).toLocaleString('en-US'),
  usdFormat: (value) => Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
};
