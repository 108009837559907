import axios from "./axios.global";

export const createOrder = async (payload) => {
	try {
		let response = await axios.post("/payments/invoice/create-order/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const paymentStatus = async (payload) => {
	try {
		let response = await axios.post(`/payments/invoice/success/`, payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
