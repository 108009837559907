import { makeStyles } from "@material-ui/core";
import theme from "../../../theme";

const useStyles = makeStyles({
	avataarContainer: {
		alignSelf: 'center',
	},
	avataar: {
		width: theme.spacing(8),
		height: theme.spacing(8),
	},
	padder: {
		padding: theme.typography.pxToRem(20),
	},
	headerStyle: {
		fontWeight: "bolder",
	},
	subHeaderStyle: {
		opacity: "50%",
	},
	imageWidth: {
		maxWidth: theme.typography.pxToRem(20),
		opacity: "50%",
		paddingTop: theme.typography.pxToRem(10)

	},
});

export default useStyles;
