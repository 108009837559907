import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles({
	logoText: {
		letterSpacing: theme.typography.pxToRem(7),
		fontWeight: "bolder !important",
	},
});

export default useStyles;
