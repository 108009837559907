//#endregion
import React from "react";
import { useHistory } from "react-router-dom";
import { getPersistentAuthentication } from "../../utils/functions";

const Authenticator = ({ shouldAuthenticate = true, ...props }) => {
	let authentication = getPersistentAuthentication();

	let history = useHistory();
	// authentication.shouldAuthenticate = false;

	if (shouldAuthenticate) {
		console.log("token", authentication?.token);
		if (authentication?.token) {
			return <>{props.children}</>;
		}
		if (authentication.mememberMe) {
			history.push("/app/dashboard");
		} else {
			history.push("/app/sign-in");
		}
	}
	return <>{props.children}</>;
};

export default Authenticator;
