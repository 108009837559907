export default () => ({
  rootStyle: {
    width: "100%",
    border: "1px solid #14004750",
    borderRadius: "5px",
  },

  outlinedRootStyle: {
    padding: "0px !important",
  },

  adornmentStyle: {
    height: "2.5em",
    width: "5em",
    justifyContent: "center",
    //background: "#ffffff",
    borderRadius: "5px",
    maxHeight: "none",
  },
  noBorder: {
    border: "none",
  },
});
