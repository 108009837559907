import React from "react";
import useStyles from "./styles";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function SimpleDropdown({
	id,
	label,
	onChange,
	menuItem,
	defaultValue,
	variant,
}) {
	const { dropdownStyle } = useStyles();
	return (
		<FormControl variant={variant} className={dropdownStyle}>
			<InputLabel shrink id={id}>{label}</InputLabel>
			<Select
				defaultValue={defaultValue}
				onChange={onChange}
				label={label}
				labelId={id}
				fullWidth
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{menuItem?.map((item) =>
					typeof item === "object" && item !== null ? (
						<MenuItem value={item.key}>{item.title}</MenuItem>
					) : (
						<MenuItem value={item}>{item}</MenuItem>
					)
				)}
			</Select>
		</FormControl>
	);
}

SimpleDropdown.propTypes = {
	children: PropTypes.object,
	close: PropTypes.func,
	open: PropTypes.bool,
	anchorEl: PropTypes.object,
	def: PropTypes,
};
