import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid, MenuItem, TextField, Typography } from "@material-ui/core";

import useStyles from "./styles";
import { getStateDetails } from "../../apis/extra.apis";

const InvoiceForm = ({ handleInvoiceDetail }) => {
	const { typographyStyle, formContainer } = useStyles();
	const [invoiceFrom, setInvoiceFrom] = useState([]);
	const [otherDetails, setOtherDetails] = useState({});
	const [buyerDetails, setBuyerDetails] = useState({});
	const [shippingDetails, setShippingDetails] = useState([]);
	const [stateDetails, setStateDetails] = useState([]);

	const handleInvoiceFrom = (e) => {
		const { name, value } = e.target;
		setInvoiceFrom({ ...invoiceFrom, [name]: value });
	};

	const handleShippingDetails = (e) => {
		const { name, value } = e.target;
		setShippingDetails({ ...shippingDetails, [name]: value });
	};

	const handleOtherDetails = (e) => {
		const { name, value } = e.target;
		if (name === "state") {
			setOtherDetails({
				...otherDetails,
				[name]: value,
				stateCode: stateDetails[value],
			});
		} else {
			setOtherDetails({ ...otherDetails, [name]: value });
		}
	};

	const handleBuyerDetails = (e) => {
		const { name, value } = e.target;
		setBuyerDetails({ ...buyerDetails, [name]: value });
	};

	useEffect(() => {
		handleInvoiceDetail({
			invoice_from: invoiceFrom,
			buyers_details: buyerDetails,
			shipping_details: shippingDetails,
			invoice_to: shippingDetails,
			status: "Pending",
			...otherDetails,
		});
	}, [invoiceFrom, otherDetails, buyerDetails]);

	useEffect(() => {
		getStateDetails()
			.then((res) => setStateDetails(res.data))
			.catch((err) => console.log(err));
	}, []);

	return (
		<form>
			<Grid container spacing={3} className={formContainer}>
				<Grid item xs={12}>
					<Typography variant="h6"> Invoice Details</Typography>
				</Grid>
				{/* <Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Invoice No.</Typography>
					<TextField fullWidth variant="outlined" size="small" />
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Dated</Typography>
					<TextField type="date" fullWidth variant="outlined" size="small" />
				</Grid> */}
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Firm Name</Typography>
					<TextField
						name="name"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Address</Typography>
					<TextField
						name="city"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>State</Typography>
					<TextField
						name="state"
						fullWidth
						select
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					>
						{Object.keys(stateDetails).map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>GSTIN</Typography>
					<TextField
						name="gstin"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Contact</Typography>
					<TextField
						name="contact"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Pan No.</Typography>
					<TextField
						name="pancode"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Vendor Code</Typography>
					<TextField
						name="vendorcode"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleInvoiceFrom}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6">Shipping Details</Typography>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Firm Name</Typography>
					<TextField
						name="name"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleShippingDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Address</Typography>
					<TextField
						name="city"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleShippingDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>State</Typography>
					<TextField
						name="state"
						fullWidth
						select
						variant="outlined"
						size="small"
						onChange={handleShippingDetails}
					>
						{Object.keys(stateDetails).map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>GSTIN</Typography>
					<TextField
						name="gstin"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleShippingDetails}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6">Other Details</Typography>
				</Grid>
				{/* <Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Delivery Note</Typography>
					<TextField
						name="delivery_note"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleOtherDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>
						Mode / Terms of Payment
					</Typography>
					<TextField
						name="mode_of_payment"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleOtherDetails}
					/>
				</Grid> */}
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Type</Typography>
					<TextField
						name="type"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleOtherDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>State</Typography>
					<TextField
						name="state"
						fullWidth
						select
						variant="outlined"
						size="small"
						onChange={handleOtherDetails}
					>
						{Object.keys(stateDetails).map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>State Code</Typography>
					<TextField
						name="stateCode"
						fullWidth
						disabled
						variant="outlined"
						size="small"
						value={stateDetails[otherDetails?.state]}
						onChange={handleOtherDetails}
					>
						{console.log(
							"state details",
							stateDetails[otherDetails?.state],
							otherDetails?.state
						)}
					</TextField>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Project Zone</Typography>
					<TextField
						name="pzone"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleOtherDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>HSN/SAC Code</Typography>
					<TextField
						name="hsncode"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleOtherDetails}
					/>
				</Grid>
				{/* <Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Buyer's Order No.</Typography>
					<TextField
						name="buyer_order_no"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Buyer's Date.</Typography>
					<TextField
						name="buyer_date"
						type="date"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Dispatch Doc No.</Typography>
					<TextField
						name="dispatch_doc_no"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid> */}
				{/* <Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Delivery Date</Typography>
					<TextField
						name="delivery_note_date"
						type="date"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>
						Dispatched Through
					</Typography>
					<TextField
						name="dispaatched_through"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Destination</Typography>
					<TextField
						name="destination"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={3}>
					<Typography className={typographyStyle}>Terms of Delivery</Typography>
					<TextField
						name="terms_of_delivery"
						fullWidth
						variant="outlined"
						size="small"
						onChange={handleBuyerDetails}
					/>
				</Grid> */}
			</Grid>
		</form>
	);
};

InvoiceForm.propTypes = {};

export default InvoiceForm;
