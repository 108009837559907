import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomButton from "../../buttons/CustomButton";
import style from "./style";

const useStyles = makeStyles(style);

const MenuDropdown = ({
  options,
  value,
  valueIdentifier,
  labelIdentifier,
  onChange,
}) => {
  const {
    containerStyle,
    menuStyle,
    menuItemStyle,
    menuItemIcon,
    buttonContainer,
    menuList,
    noBorder,
    formLabelFocused,
  } = useStyles();

  const [val, setVal] = useState();

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = (e) => {
    setVal(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  return (
    <TextField
      select
      value={val}
      variant="outlined"
      onChange={handleChange}
      renderValue={() => val}
      className={containerStyle}
      size="small"
      InputProps={{
        classes: {
          notchedOutline: noBorder,
        },
      }}
      SelectProps={{
        renderValue: (value) => value,
        MenuProps: {
          MenuListProps: {
            classes: {
              root: menuList,
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: menuStyle,
          },
        },
      }}
      MenuProps={{
        PopoverClasses: {
          paper: menuStyle,
        },
      }}
      InputLabelProps={{
        shrink: true,
        FormLabelClasses: {
          root: {
            "&:focused": formLabelFocused,
          },
          focused: "true",
        },
      }}
    >
      {options.map((opt, key) => (
        <MenuItem
          key={key}
          value={opt[valueIdentifier]}
          className={menuItemStyle}
        >
          <ListItemText primary={opt[labelIdentifier]} />
          <ListItemIcon className={menuItemIcon}>
            <ChevronRightIcon />
          </ListItemIcon>
        </MenuItem>
      ))}
      <Grid item xs={12} className={buttonContainer}>
        <CustomButton label={"CREATE"} />
      </Grid>
    </TextField>
  );
};

MenuDropdown.propTypes = {
  options: PropTypes.shape([]).isRequired,
  onChange: PropTypes.func,
};

MenuDropdown.propTypes = {
  onChange: () => {},
};

export default MenuDropdown;
