import axios from "./axios.global";

export const getDashboards = async () => {
	try {
		let response = await axios.get("/inventory/dashboard/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getHeader = async () => {
	try {
		let response = await axios.get("/dashboard/header/");
		console.log("data", response.data);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getSplineChart = async (payload) => {
	try {
		let response = await axios.get(`/dashboard/spline/?duration=${payload}`);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getMapChart = async (value) => {
	try {
		let response = await axios.get(`/dashboard/maps/?type=${value}`);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
