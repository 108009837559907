import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";

export default function SimpleMenu(props) {
	const { children, anchorEl, open, close, def } = props;
	const [anchorElement, setAnchorElement] = useState(null);
	const { dropdown, paper, dropdownSideNav } = useStyles();
	console.log("children", children);

	useEffect(() => {
		setAnchorElement(anchorEl);
	}, [anchorEl]);

	const handleClose = () => {
		// setAnchorElement(null);
		close();
	};

	return (
		<div>
			<Menu
				id="simple-menu"
				anchorEl={anchorElement}
				className={!def ? dropdown : dropdownSideNav}
				classes={{ paper: !def ? paper : dropdownSideNav }}
				keepMounted
				open={open}
				onClose={handleClose}
				// onBlur={close}
			>
				{children}
			</Menu>
		</div>
	);
}

SimpleMenu.propTypes = {
	children: PropTypes.object,
	close: PropTypes.func,
	open: PropTypes.bool,
	anchorEl: PropTypes.object,
	def: PropTypes,
};
