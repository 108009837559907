import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles({
	imageContainer: {
		backgroundImage:
			"linear-gradient(to bottom, #242348, #302f5f, #3e3b77, #4b4890, #5a55aa)",
	},
	imageStyle: {
		width: "100%",
		maxHeight: '100vh',
		objectFit: 'cover'
	},
	formContainer: {
		textAlign: "center",
	},
	logoText: {
		letterSpacing: theme.typography.pxToRem(7),
		fontSize: theme.typography.pxToRem(35),
		fontWeight: "bolder !important",
	},
	subTitleText: {
		color: "#b4b4b9",
		padding: theme.typography.pxToRem(10),
	},
	textCentered: {
		alignSelf: "center",
	},
	logo: {
		// height : theme.typography.pxToRem(122)
		maxWidth: "37rem",
	},
	error: {
		color: "#ff3d3d",
		fontSize: "1rem",
		// paddingTop: "3rem",
		// paddingBottom: "3rem",
		// marginTop: "0.3rem",
		textAlign: "start",
		marginBottom: "2rem",
		paddingLeft: "1rem",
		paddingRight: "1rem",
	},
	belowInput: {
		display: "flexbox",
		flexDirection: "row",
		fontSize: `${theme.typography.pxToRem(18)}`,
	},
	checkbox: {
		float: "left",
	},
	label: {
		fontSize: theme.typography.pxToRem(22),
	},
	forgotPassword: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: `${theme.typography.pxToRem(22)}`,
		color: "#6633F2",
		cursor: "pointer",
	},
	button: {
		marginTop: theme.typography.pxToRem(47),
		padding: `${theme.typography.pxToRem(20)} !important`,
		fontSize: theme.typography.pxToRem(24),
	},
});

export default useStyles;
// margin-bottom: 2rem;
// text-align: start;
