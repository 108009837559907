import BasicLayout from "./components/BasicLayout";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import InventoryPage from "./pages/InventoryPage/InventoryPage";
import Invoice from "./pages/Invoice";
import InvoicePage from "./pages/InvoicePage";
import LoginPage from "./pages/LoginPage";
import PasswordLinkPage from "./pages/PasswordLinkPage/PasswordLinkPage";
import RequestPage from "./pages/RequestPage";
import TeamPage from "./pages/TeamPage";
import InvoiceHistoryPage from "./pages/InvoiceHistoryPage";
import InvoiceAcceptancePage from "./pages/InvoiceAcceptancePage";
import InvoicePrintable from "./pages/InvoicePrintable";
import DCPage from "./pages/DCPage";
import BillsPage from "./pages/BiillsPage";
import ActivityPage from "./pages/ActivityPage";
import ConveyancePage from "./pages/ConveyancePage";
import InvoiceDummy from "./pages/InvoiceDummyPage/InvoiceDummy";
import DeliveryHistoryPage from "./pages/DeliveryHistoryPage";
import BillHistoryPage from "./pages/BillHistoryPage";
import ActivityHistoryPage from "./pages/ActivityHistoryPage";
import ConveyanceHistoryPage from "./pages/ConveyanceHistoryPage";

export const RouteConfig = {
	routes: [
		{
			path: "/basic",
			component: BasicLayout,
		},
		{
			path: "/sign-in",
			component: LoginPage,
			shouldAuthenticate: false,
		},
		{
			path: "/forgot-password",
			component: PasswordLinkPage,
			shouldAuthenticate: false,
		},
		{
			path: "/inventory",
			component: InventoryPage,
			shouldAuthenticate: false,
		},

		{
			path: `/dashboard`,
			component: DashboardPage,
			shouldAuthenticate: true,
		},
		{
			path: `/teams`,
			component: TeamPage,
			shouldAuthenticate: true,
		},
		{
			path: `/orders`,
			component: RequestPage,
			shouldAuthenticate: true,
		},
		{
			path: `/invoices`,
			component: InvoiceAcceptancePage,
			shouldAuthenticate: true,
		},
		{
			path: `/invoices/:type`,
			component: InvoicePage,
			shouldAuthenticate: true,
		},
		{
			path: `/invoice/:id`,
			component: Invoice,
			shouldAuthenticate: true,
		},
		{
			path: `/print-invoice/:id`,
			component: InvoicePrintable,
		},
		{
			path: `/invoice-history`,
			component: InvoiceHistoryPage,
			shouldAuthenticate: true,
		},
		{
			path: `/delivery`,
			component: DCPage,
			shouldAuthenticate: true,
		},
		{
			path: `/bills`,
			component: BillsPage,
			shouldAuthenticate: true,
		},
		{
			path: `/activity`,
			component: ActivityPage,
			shouldAuthenticate: true,
		},
		{
			path: `/conveyance`,
			component: ConveyancePage,
			shouldAuthenticate: true,
		},
		{
			path: `/invoice-dummy`,
			component: InvoiceDummy,
			shouldAuthenticate: false,
		},
		{
			path: `/delivery/history`,
			component: DeliveryHistoryPage,
			shouldAuthenticate: false,
		},
		{
			path: `/bills/history`,
			component: BillHistoryPage,
			shouldAuthenticate: false,
		},
		{
			path: `/activity/history`,
			component: ActivityHistoryPage,
			shouldAuthenticate: false,
		},
		{
			path: `/conveyance/history`,
			component: ConveyanceHistoryPage,
			shouldAuthenticate: false,
		},
	],
	basePath: "/app",
};
