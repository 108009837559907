import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Authenticator from "./components/Authenticator";
import { Loader } from "./components/Preloader/Preloader";
import { Toast } from "./components/Toast";
import LoginPage from "./pages/LoginPage";
import { RouteConfig } from "./routes";
import { getPath, getPersistentAuthentication } from "./utils/functions";

const TvRouter = () => {
	const authentication = getPersistentAuthentication();
	return (
		<Switch>
			{RouteConfig.routes.map(({ component: Component, ...route }, index) => (
				<Route
					key={index}
					path={getPath(route.path, RouteConfig.basePath)}
					exact
					render={(props) => (
						<Authenticator shouldAuthenticate={route.shouldAuthenticate}>
							<Loader {...props}>
								<Toast {...props}>{<Component />}</Toast>
							</Loader>
						</Authenticator>
					)}
				/>
			))}
			<Route path="/app/sign-in" component={LoginPage} />
			{authentication?.rememberMe ? (
				<Redirect from="/" exact to="/app/dashboard" />
			) : (
				<Redirect from="/" exact to="/app/sign-in" />
			)}
			{authentication?.rememberMe ? (
				<Redirect from="/app" exact to="/app/dashboard" />
			) : (
				<Redirect from="/app" exact to="/app/sign-in" />
			)}
		</Switch>
	);
};

export default TvRouter;
