import { makeStyles } from "@material-ui/core";
import theme from "../../../theme";

const drawerWidth = 240;

export const useStyles = makeStyles({
	profileAvatarContainer: {
		width: theme.typography.pxToRem(51),
		display: "flex",
		alignItems: "center",
		flex: 1,
		justifyContent: "flex-end",
	},
	avatar: {
		width: theme.typography.pxToRem(51),
		height: theme.typography.pxToRem(51),
		background: "#6633F2",
		marginLeft: theme.typography.pxToRem(10)
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: "white",
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	dividerStyle: {
		margin: theme.typography.pxToRem(10),
		height: theme.typography.pxToRem(45),
		
	}
});
