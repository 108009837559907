export default class ActivityIM {
	constructor(data) {
		this.id = data.id;
		this.activity_id = data.activity_id;
		this.circle = data.circle;
		this.site = data.site_name;
		this.zone = data.zone;
		this.rate = data.rate;
		this.date = data.activity_date;
		this.total = data.total;
		this.cab_approved = data.cab_approved;
		this.type = data.type;
		this.status = data.wcc_status
	}
}
