import Popup from "../../components/Popup";
import { Grid, TextField, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { editChallan } from "../../apis/dc.apis";

export default function ({title, handleClose, data }) {
	const { values, handleSubmit, handleBlur, handleChange, setFieldValue } =
		useFormik({
			initialValues: data,
			onSubmit: (values) => {
				editChallan( values,data.id)
					.then((res) => {
						console.log(res);
						handleClose();

					})
					.catch((err) => console.log(err));
			},
		});

	return (
		<Popup name={title} open={true} handleClose={handleClose}>
			<form
				style={{
					padding: "25px",
				}}
				onSubmit={handleSubmit}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							id="dc_no"
							variant="outlined"
							label="Challan Number"
							name="dc_no"
							fullWidth
							value={values.dc_no}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="date"
							variant="outlined"
							name="date"
							fullWidth
							type="date"
							value={values.date}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="location"
							variant="outlined"
							label="Location"
							name="location"
							fullWidth
							value={values.location}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="amount"
							variant="outlined"
							label="Amount"
							name="amount"
							fullWidth
							value={values.amount}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="file_link"
							variant="outlined"
							label="File Link"
							name="file_link"
							fullWidth
							value={values.file_link}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							size="large"
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		</Popup>
	);
}
