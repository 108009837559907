export default {
	title: "India",
	version: "1.1.3",
	type: "FeatureCollection",
	crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG:24373" } },
	"hc-transform": {
		default: {
			crs: "+proj=lcc +lat_1=19 +lat_0=19 +lon_0=80 +k_0=0.99878641 +x_0=2743195.592233322 +y_0=914398.5307444407 +a=6377299.36559538 +b=6356098.359005156 +to_meter=0.9143985307444408 +no_defs",
			scale: 0.6,
			jsonres: 15.5,
			jsonmarginX: -999,
			jsonmarginY: 9851.0,
			xoffset: 1676654.55745,
			yoffset: 3026618.02308,
		},
	},
	features: [
		{
			type: "Feature",
			id: "IN.SK",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.46,
				"hc-middle-y": 0.51,
				"hc-key": "in-sk",
				"hc-a2": "SK",
				labelrank: "2",
				hasc: "IN.SK",
				"alt-name": null,
				"woe-id": "2345762",
				subregion: null,
				fips: "IN29",
				"postal-code": "SK",
				name: "Sikkim",
				country: "India",
				"type-en": "State",
				region: "East",
				longitude: "88.4482",
				"woe-name": "Sikkim",
				latitude: "27.5709",
				"woe-label": "Sikkim, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[6115, 6597],
						[6117, 6694],
						[6160, 6807],
						[6141, 6875],
						[6233, 6893],
						[6312, 6946],
						[6387, 6906],
						[6403, 6847],
						[6369, 6727],
						[6383, 6673],
						[6427, 6638],
						[6374, 6570],
						[6298, 6577],
						[6248, 6538],
						[6157, 6555],
						[6115, 6597],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.NL",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.63,
				"hc-middle-y": 0.55,
				"hc-key": "in-nl",
				"hc-a2": "NL",
				labelrank: "2",
				hasc: "IN.NL",
				"alt-name": null,
				"woe-id": "2345754",
				subregion: null,
				fips: "IN20",
				"postal-code": "NL",
				name: "Nagaland",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "94.5664",
				"woe-name": "Nagaland",
				latitude: "26.1094",
				"woe-label": "Nagaland, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[8692, 6530],
						[8648, 6508],
						[8624, 6455],
						[8634, 6366],
						[8678, 6291],
						[8629, 6236],
						[8638, 6164],
						[8559, 6060],
						[8520, 6039],
						[8477, 6062],
						[8485, 6130],
						[8402, 6055],
						[8361, 6045],
						[8294, 6063],
						[8215, 6052],
						[8220, 6014],
						[8155, 5914],
						[8096, 5948],
						[8096, 5948],
						[8096, 5948],
						[8096, 5960],
						[8097, 5979],
						[8088, 5990],
						[8048, 6037],
						[8203, 6209],
						[8197, 6165],
						[8241, 6178],
						[8269, 6216],
						[8268, 6310],
						[8322, 6389],
						[8349, 6463],
						[8382, 6444],
						[8422, 6518],
						[8492, 6549],
						[8565, 6639],
						[8606, 6635],
						[8654, 6679],
						[8668, 6614],
						[8692, 6530],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.MN",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.51,
				"hc-middle-y": 0.48,
				"hc-key": "in-mn",
				"hc-a2": "MN",
				labelrank: "2",
				hasc: "IN.MN",
				"alt-name": null,
				"woe-id": "2345751",
				subregion: null,
				fips: "IN17",
				"postal-code": "MN",
				name: "Manipur",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "93.84569999999999",
				"woe-name": "Manipur",
				latitude: "24.7442",
				"woe-label": "Manipur, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[8096, 5948],
						[8155, 5914],
						[8220, 6014],
						[8215, 6052],
						[8294, 6063],
						[8361, 6045],
						[8402, 6055],
						[8485, 6130],
						[8477, 6062],
						[8520, 6039],
						[8491, 5957],
						[8544, 5919],
						[8555, 5875],
						[8521, 5762],
						[8496, 5744],
						[8428, 5585],
						[8402, 5456],
						[8374, 5394],
						[8335, 5420],
						[8265, 5422],
						[8193, 5445],
						[8139, 5421],
						[8104, 5465],
						[8081, 5447],
						[8010, 5445],
						[7969, 5462],
						[7968, 5582],
						[7993, 5654],
						[7994, 5746],
						[8018, 5748],
						[8078, 5920],
						[8096, 5948],
						[8096, 5948],
						[8096, 5948],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.AR",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.53,
				"hc-middle-y": 0.38,
				"hc-key": "in-ar",
				"hc-a2": "AR",
				labelrank: "2",
				hasc: "IN.AR",
				"alt-name":
					"Agence de la Frontisre du Nord-Est(French-obsolete)|North East Frontier Agency",
				"woe-id": "2345763",
				subregion: null,
				fips: "IN30",
				"postal-code": "AR",
				name: "Arunachal Pradesh",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "94.46729999999999",
				"woe-name": "Arunachal Pradesh",
				latitude: "28.4056",
				"woe-label": "Arunachal Pradesh, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[7563, 6550],
						[7527, 6641],
						[7554, 6701],
						[7511, 6765],
						[7433, 6737],
						[7375, 6785],
						[7383, 6870],
						[7504, 6865],
						[7536, 6894],
						[7618, 6897],
						[7729, 6956],
						[7751, 7006],
						[7733, 7032],
						[7799, 7071],
						[7879, 7147],
						[7897, 7199],
						[7993, 7277],
						[8056, 7282],
						[8227, 7385],
						[8297, 7441],
						[8267, 7468],
						[8308, 7515],
						[8353, 7529],
						[8385, 7567],
						[8441, 7510],
						[8517, 7500],
						[8503, 7517],
						[8621, 7480],
						[8627, 7514],
						[8698, 7519],
						[8722, 7568],
						[8767, 7559],
						[8772, 7609],
						[8909, 7632],
						[8935, 7590],
						[8980, 7603],
						[8977, 7560],
						[8930, 7530],
						[8940, 7499],
						[8992, 7531],
						[9081, 7424],
						[9092, 7386],
						[9039, 7342],
						[9067, 7269],
						[9083, 7335],
						[9132, 7342],
						[9235, 7248],
						[9286, 7268],
						[9365, 7216],
						[9359, 7166],
						[9387, 7129],
						[9381, 7089],
						[9342, 7087],
						[9229, 6964],
						[9249, 6882],
						[9341, 6779],
						[9304, 6762],
						[9235, 6794],
						[9200, 6851],
						[9139, 6850],
						[9115, 6823],
						[8986, 6798],
						[8943, 6767],
						[8905, 6699],
						[8867, 6682],
						[8807, 6609],
						[8772, 6601],
						[8742, 6551],
						[8692, 6530],
						[8668, 6614],
						[8654, 6679],
						[8737, 6729],
						[8776, 6785],
						[8812, 6771],
						[8902, 6797],
						[8935, 6836],
						[8922, 6868],
						[8893, 6846],
						[8886, 6907],
						[8841, 6967],
						[8897, 7082],
						[8810, 7073],
						[8753, 7033],
						[8672, 7023],
						[8388, 6879],
						[8304, 6894],
						[8308, 6846],
						[8179, 6705],
						[8175, 6664],
						[8131, 6625],
						[8050, 6595],
						[8019, 6610],
						[7895, 6586],
						[7819, 6619],
						[7609, 6549],
						[7563, 6550],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.MZ",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.53,
				"hc-middle-y": 0.39,
				"hc-key": "in-mz",
				"hc-a2": "MZ",
				labelrank: "2",
				hasc: "IN.MZ",
				"alt-name": null,
				"woe-id": "20070461",
				subregion: null,
				fips: "IN31",
				"postal-code": "MZ",
				name: "Mizoram",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "92.84090000000001",
				"woe-name": "Mizoram",
				latitude: "23.2037",
				"woe-label": "Mizoram, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[8081, 5447],
						[8116, 5332],
						[8139, 5298],
						[8134, 5094],
						[8110, 5048],
						[8052, 5039],
						[8069, 4991],
						[8038, 4946],
						[8052, 4845],
						[8080, 4798],
						[8077, 4719],
						[8034, 4721],
						[8024, 4639],
						[7977, 4647],
						[7912, 4694],
						[7903, 4640],
						[7877, 4622],
						[7872, 4697],
						[7828, 4901],
						[7769, 5001],
						[7754, 5083],
						[7761, 5129],
						[7715, 5252],
						[7715, 5289],
						[7732, 5348],
						[7725, 5481],
						[7714, 5500],
						[7756, 5503],
						[7773, 5462],
						[7831, 5508],
						[7869, 5603],
						[7901, 5568],
						[7968, 5582],
						[7969, 5462],
						[8010, 5445],
						[8081, 5447],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.TR",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.51,
				"hc-middle-y": 0.46,
				"hc-key": "in-tr",
				"hc-a2": "TR",
				labelrank: "2",
				hasc: "IN.TR",
				"alt-name": null,
				"woe-id": "2345759",
				subregion: null,
				fips: "IN26",
				"postal-code": "TR",
				name: "Tripura",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "91.70310000000001",
				"woe-name": "Tripura",
				latitude: "23.8519",
				"woe-label": "Tripura, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[7714, 5500],
						[7725, 5481],
						[7732, 5348],
						[7715, 5289],
						[7675, 5298],
						[7624, 5261],
						[7598, 5289],
						[7605, 5200],
						[7555, 5146],
						[7540, 5108],
						[7563, 5037],
						[7491, 4975],
						[7441, 5066],
						[7406, 5095],
						[7410, 5017],
						[7383, 5046],
						[7348, 5172],
						[7313, 5227],
						[7333, 5344],
						[7375, 5381],
						[7381, 5423],
						[7452, 5424],
						[7473, 5470],
						[7511, 5484],
						[7560, 5455],
						[7576, 5532],
						[7641, 5561],
						[7652, 5615],
						[7695, 5561],
						[7685, 5508],
						[7714, 5500],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.AS",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.53,
				"hc-middle-y": 0.45,
				"hc-key": "in-as",
				"hc-a2": "AS",
				labelrank: "2",
				hasc: "IN.AS",
				"alt-name": null,
				"woe-id": "2345741",
				subregion: null,
				fips: "IN03",
				"postal-code": "AS",
				name: "Assam",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "92.99290000000001",
				"woe-name": "Assam",
				latitude: "26.3302",
				"woe-label": "Assam, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[6781, 6422],
						[6863, 6434],
						[6892, 6472],
						[6963, 6500],
						[7038, 6460],
						[7085, 6458],
						[7254, 6482],
						[7282, 6473],
						[7326, 6513],
						[7368, 6488],
						[7432, 6494],
						[7498, 6529],
						[7549, 6522],
						[7563, 6550],
						[7609, 6549],
						[7819, 6619],
						[7895, 6586],
						[8019, 6610],
						[8050, 6595],
						[8131, 6625],
						[8175, 6664],
						[8179, 6705],
						[8308, 6846],
						[8304, 6894],
						[8388, 6879],
						[8672, 7023],
						[8753, 7033],
						[8810, 7073],
						[8897, 7082],
						[8841, 6967],
						[8886, 6907],
						[8893, 6846],
						[8922, 6868],
						[8935, 6836],
						[8902, 6797],
						[8812, 6771],
						[8776, 6785],
						[8737, 6729],
						[8654, 6679],
						[8606, 6635],
						[8565, 6639],
						[8492, 6549],
						[8422, 6518],
						[8382, 6444],
						[8349, 6463],
						[8322, 6389],
						[8268, 6310],
						[8269, 6216],
						[8241, 6178],
						[8197, 6165],
						[8203, 6209],
						[8048, 6037],
						[8084, 6002],
						[8088, 5990],
						[8096, 5960],
						[8098, 5956],
						[8096, 5948],
						[8096, 5948],
						[8096, 5948],
						[8078, 5920],
						[8018, 5748],
						[7994, 5746],
						[7993, 5654],
						[7968, 5582],
						[7901, 5568],
						[7869, 5603],
						[7831, 5508],
						[7773, 5462],
						[7756, 5503],
						[7714, 5500],
						[7685, 5508],
						[7695, 5561],
						[7652, 5615],
						[7679, 5760],
						[7727, 5737],
						[7762, 5775],
						[7724, 5803],
						[7784, 5865],
						[7868, 5901],
						[7859, 5948],
						[7783, 6002],
						[7782, 6037],
						[7702, 6101],
						[7627, 6071],
						[7651, 6213],
						[7615, 6223],
						[7531, 6198],
						[7495, 6216],
						[7460, 6165],
						[7410, 6185],
						[7376, 6118],
						[7336, 6108],
						[7284, 6062],
						[7277, 6114],
						[7219, 6097],
						[7196, 6139],
						[7122, 6140],
						[7041, 6116],
						[7042, 6144],
						[6980, 6154],
						[6897, 6127],
						[6843, 6061],
						[6834, 6017],
						[6858, 5977],
						[6792, 5916],
						[6797, 5993],
						[6775, 6073],
						[6790, 6110],
						[6722, 6207],
						[6780, 6266],
						[6796, 6348],
						[6781, 6422],
					],
				],
			},
		},
		{
			type: "Feature",
			id: "IN.ML",
			properties: {
				"hc-group": "admin1",
				"hc-middle-x": 0.31,
				"hc-middle-y": 0.74,
				"hc-key": "in-ml",
				"hc-a2": "ML",
				labelrank: "2",
				hasc: "IN.ML",
				"alt-name": null,
				"woe-id": "2345752",
				subregion: null,
				fips: "IN18",
				"postal-code": "ML",
				name: "Meghalaya",
				country: "India",
				"type-en": "State",
				region: "Northeast",
				longitude: "91.3031",
				"woe-name": "Meghalaya",
				latitude: "25.4804",
				"woe-label": "Meghalaya, IN, India",
				type: "State",
			},
			geometry: {
				type: "Polygon",
				coordinates: [
					[
						[7724, 5803],
						[7693, 5828],
						[7582, 5863],
						[7485, 5851],
						[7462, 5833],
						[7394, 5831],
						[7308, 5853],
						[7161, 5820],
						[7130, 5829],
						[6997, 5813],
						[6831, 5862],
						[6805, 5854],
						[6792, 5916],
						[6858, 5977],
						[6834, 6017],
						[6843, 6061],
						[6897, 6127],
						[6980, 6154],
						[7042, 6144],
						[7041, 6116],
						[7122, 6140],
						[7196, 6139],
						[7219, 6097],
						[7277, 6114],
						[7284, 6062],
						[7336, 6108],
						[7376, 6118],
						[7410, 6185],
						[7460, 6165],
						[7495, 6216],
						[7531, 6198],
						[7615, 6223],
						[7651, 6213],
						[7627, 6071],
						[7702, 6101],
						[7782, 6037],
						[7783, 6002],
						[7859, 5948],
						[7868, 5901],
						[7784, 5865],
						[7724, 5803],
					],
				],
			},
		},
	],
};
