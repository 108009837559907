export default class ConveyanceIM {
	constructor(data) {
		this.id = data.id;
		this.activity_id = data.activity_id;
		this.call_no = data.call_no;
		this.status = data.status;
		this.site_id = data.site_id;
		this.location = data.location;
		this.date = data.date;
		this.advance = data.advance_paid;
		this.call_charge = data.call_charge
		this.hotel = data.hotel;
		this.purchase = data.purchase;
		this.due = data.total_due;
		this.overdue = data.total_overdue;

	}
}
