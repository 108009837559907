import { Drawer, Grid, IconButton, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, memo } from "react";
import { useBasicNav } from "../../../utils/useBasicNav";
import { useStyles } from "./Styles";
import CloseIcon from "@material-ui/icons/Close";
import TextLogo from "../../TextLogo";
import MenuIcon from "@material-ui/icons/Menu";

import clsx from "clsx";
import {
	chat,
	customers,
	dashboard,
	help,
	invoice,
	process,
	products,
	setting,
} from "../../../assets/images";

const navConfigAdmin = [
	{ text: "Dashboard", icon: dashboard, navTag: "dashboard" },
	// { text: "All Inventory", icon: products, navTag: "inventory" },
	// { text: "Add a request", icon: process, navTag: "orders" },
	{ text: "Delivery Challan", icon: process, navTag: "delivery" },
	{ text: "Submit Invoices", icon: invoice, navTag: "invoices" },
	{ text: "Team Members", icon: customers, navTag: "teams" },
	{ text: "Miscellaneous Bill", icon: products, navTag: "bills" },
	{ text: "Activity", icon: help, navTag: "activity" },
	{ text: "Conveyance", icon: chat, navTag: "conveyance" },

	// { text: "Help Center", icon: help, navTag: "help" },
	// { text: "Notifications", icon: chat, navTag: "notifications" },
	// { text: "Settings", icon: setting, navTag: "settings" },
];

const navConfig = [
	// { text: "All Inventory", icon: products, navTag: "inventory" },
	// { text: "Add a request", icon: process, navTag: "orders" },
	{ text: "Delivery Challan", icon: process, navTag: "delivery" },
	{ text: "Submit Invoices", icon: invoice, navTag: "invoices" },
	{ text: "Team Members", icon: customers, navTag: "teams" },
	{ text: "Miscellaneous Bill", icon: products, navTag: "bills" },
	{ text: "Activity", icon: help, navTag: "activity" },

	// { text: "Help Center", icon: help, navTag: "help" },
	// { text: "Notifications", icon: chat, navTag: "notifications" },
	// { text: "Settings", icon: setting, navTag: "settings" },
];

function SideNav({ navState, onOpen, username }) {
	const classes = useStyles();

	const { activeTab, setActiveTab } = useBasicNav();
	const [open, setOpen] = React.useState(false);

	const handleDrawerClose = () => {
		onOpen(false);
	};

	const handleDrawerOpen = () => {
		onOpen(true);
	};

	useEffect(() => {
		setOpen(navState);
	}, [navState]);

	console.log("active tab", activeTab);

	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<Grid container className={classes.drawerHeader}>
				{open ? (
					<>
						<Grid item xs={10}>
							<TextLogo textColor={"white"} variant={"h6"} />
						</Grid>
						<Grid item xs={2}>
							<IconButton onClick={handleDrawerClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</>
				) : (
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						size="medium"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<MenuIcon />
					</IconButton>
				)}
			</Grid>
			<Grid container className={classes.navItems}>
				{navConfigAdmin.map((item, index) =>
					username !== "rana@admin" && item.text === "Dashboard" ? (
						<></>
					) : (
						<Grid
							item
							xs={12}
							className={classNames(classes.navItem, {
								[classes.navActive]: index === activeTab,
							})}
							onClick={() => setActiveTab(item.navTag)}
						>
							<Grid item xs={2} style={{ alignSelf: "center" }}>
								<img src={item.icon} alt={item.text} />
							</Grid>
							{open && (
								<Grid item xs={10}>
									<Typography variant="h6" component="div">
										{item.text}
									</Typography>
								</Grid>
							)}
						</Grid>
					)
				)}
			</Grid>
		</Drawer>
	);
}

export default memo(SideNav);
