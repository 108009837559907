import axios from "./axios.global";

export const generateBillSingle = async (payload) => {
	try {
		let response = await axios.post("/challan/misc-bills/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllBills = async (payload) => {
	try {
		let response = await axios.get("/challan/misc-bills/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const generateBillBulk = async (payload) => {
	try {
		let response = await axios.post(`/challan/bulk/`, payload, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (err) {
		console.log({ err });
		throw new Error(err);
	}
};

export const getBillBulk = async (payload) => {
	try {
		let response = await axios.get(`/challan/bulk/?type=Bills`, {responseType: 'blob'}
		);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const editBill = async (payload, id) => {
	try {
		let response = await axios.put(`/challan/misc-bills/${id}/`, payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const deleteBill = async (id) => {
	try {
		let response = await axios.delete(`/challan/misc-bills/${id}/`);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};