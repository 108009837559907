import React, { useEffect } from "react";
import {
	createTheme,
	ThemeProvider,
	StylesProvider,
	createGenerateClassName,
} from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import TvRouter from "./TvRouter";
import ErrorBoundary from "./components/utility/ErrorBoundary";
import theme from "./theme";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";

const generateClassName = createGenerateClassName({
	productionPrefix: "tv",
});

const customTheme = createTheme(theme);

function App() {
	useEffect(() => {}, []);
	return (
		<ErrorBoundary>
			<RecoilRoot>
				<Suspense fallback={<div>Loading...</div>}>
				<StylesProvider generateClassName={generateClassName}>
					<ThemeProvider theme={customTheme}>
						<Router>
							<TvRouter />
						</Router>
					</ThemeProvider>
				</StylesProvider>
				</Suspense>
			</RecoilRoot>
		</ErrorBoundary>
	);
}

export default App;
