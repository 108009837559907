import React, { useEffect, useState } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BasicLayout from "../../components/BasicLayout";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import InvoiceForm from "./InvoiceForm";
import ProductForm from "./ProductForm";
import CustomButton from "../../components/buttons/CustomButton";
import { useHistory, useParams } from "react-router";
import AccountForm from "./AccountForm";
import { generateInvoice } from "../../apis/invoice.apis";
import HistoryIcon from "@mui/icons-material/History";

const InvoicePage = ({ loadPrimary }) => {
	const { content, productContainer, totalContainer, totalText } = useStyles();
	const [data, setData] = useState({});
	const { type } = useParams();

	// const user = useRecoilValue(currentUser);
	const notifications = useRecoilValue(userNotification);
	const history = useHistory();

	useEffect(() => {
		loadPrimary(false);
	}, []);

	const handleGenerate = () => {
		// setGetData(!getData);
		console.log("generAte");
		generateInvoice(data)
			.then((res) => history.push(`/app/invoice/${res.invoice_no}`))
			.catch((err) => console.log(err));
		// history.push("invoice/");
	};

	const handleHistoryPage = () => {
		console.log("history page");
		loadPrimary(true);
		history.push(`/app/invoice-history`);
	};

	console.log({ data });

	const handleData = (payload) => {
		console.log({ payload });
		setData({ ...data, ...payload, invoice_type: type });
	};

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={3}>
				<Grid item xs={10}>
					<Typography variant="h4">Create Invoice</Typography>
				</Grid>
				<Grid item xs={2}>
					<CustomButton label={"History"} onButtonClick={handleHistoryPage} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<InvoiceForm handleInvoiceDetail={handleData} />
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className={productContainer}>
					<ProductForm handleProductData={handleData} />
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className={productContainer}>
					<AccountForm handleAccountData={handleData} />
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className={totalContainer}>
					<Grid container spacing={3}>
						<Grid item xs={2}>
							<Typography className={totalText}>Total Cost</Typography>
							<Typography variant="h5">
								Rs.{" "} { data?.total_amount_without_tax}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography className={totalText}>GST</Typography>
							<Typography variant="h5">{data?.total_tax}</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography className={totalText}>Total Amount</Typography>
							<Typography variant="h5">Rs.{" "}{data?.total_amount}</Typography>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={2}>
							<CustomButton
								label="Generate Invoice"
								onButtonClick={handleGenerate}
							/>
						</Grid>
						<Grid item xs={2}>
							<CustomButton isOutlined label="Cancel" />
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</>
	);
};

InvoicePage.propTypes = {};

export default InvoicePage;
