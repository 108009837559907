import React, { memo, useState } from "react";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useStyles } from "./Style";
import { useRecoilValue } from "recoil";
import { currentUser, userNotification } from "../../User.atom";
import jwt_decode from "jwt-decode";
import { getPersistentAuthentication } from "../../utils/functions";

function BasicLayout(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { notifications } = props;
	const userData = getPersistentAuthentication();
	console.log("token", userData.user);

	const handleOpen = (state) => {
		setOpen(state);
	};

	return (
		<React.Fragment>
			<TopNav
				onOpen={handleOpen}
				navState={open}
				notifcations={notifications}
			/>
			<div className={classes.container}>
				<SideNav navState={open} onOpen={handleOpen} username={userData.user} />
			</div>
		</React.Fragment>
	);
}

export default memo(BasicLayout);
