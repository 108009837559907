import axios from "./axios.global";

export const generateChallanSingle = async (payload) => {
	try {
		let response = await axios.post("/challan/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllChallan = async (payload) => {
	try {
		let response = await axios.get("/challan/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const generateChallanBulk = async (payload) => {
	try {
		let response = await axios.post(`/challan/bulk/`, payload, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};


export const getChallanBulk = async (payload) => {
	try {
		let response = await axios.get(`/challan/bulk/?type=Challan`, {responseType: 'blob'}
		);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const editChallan = async (payload, id) => {
	try {
		let response = await axios.put(`/challan/delivery-challan/${id}/`, payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const deleteChallan = async (id) => {
	try {
		let response = await axios.delete(`/challan/delivery-challan/${id}/`);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
