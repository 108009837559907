import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles({
	tableHeader: {
		backgroundColor: "#F5F6FA",
		minHeight: theme.typography.pxToRem(45),
		alignItems: "center",
		padding: theme.typography.pxToRem(10),
		margin:theme.typography.pxToRem(20),
	},
	tableCard: {
		padding: theme.typography.pxToRem(20),
	},
	productLayout: {
		margin: theme.typography.pxToRem(20),
	},
	quickDetail: {
		margin: `0px ${theme.typography.pxToRem(20)}`,
	},
	content: {
		paddingTop: theme.typography.pxToRem(40),
		paddingLeft: theme.typography.pxToRem(150),
		paddingRight: theme.typography.pxToRem(50),
		backgroundColor: "#F0F0F7",

	},
	cardStyle: {
		width: "100%",
		padding: theme.typography.pxToRem(20),
	},
	starIcon: {
		display: "flex",
	},
	divider: {
		marginTop: theme.typography.pxToRem(10),
	},

	addItems: {
		alignSelf: 'center'
	}
});

export default useStyles;
