import Highcharts from "highcharts";
import "highcharts/modules/exporting";
import { PureComponent, createRef } from "react";
import "./style.css";
import PropTypes from "prop-types";

/**A component that renders a spline chart when provided with a few options */
export default class ColumnChart extends PureComponent {
	/**
	 *
	 * @param {Object} props
	 * @param {string[]} props.categories - The Labels on the x-axis for the spline chart
	 * @param {number[]} props.productSentData - An array representing of number of units shipped for each label on x-axis
	 * @param {number[]} props.productReqData -  An array representing of number of units requested for each label on x-axis
	 */
	constructor(props) {
		super(props);
	}

	createColumnChart() {
		Highcharts.chart("column-container", {
			chart: {
				type: "column",
			},
			colors: ["#a3a1fb", "#56d9fe", "#ff8373", "#ffda83"],
			title: {
				text: "Tasks Performed",
				align: 'left'
			},
			legend: {
				verticalAlign: "top",
				align: "center",
				squareSymbol: false,
				y: 5,
				x: 10,
				symbolWidth: 20
				// floating: true
			},
			xAxis: {
				categories: this.props.categories,
			},
			yAxis: {
				min: 0,
				title: {
					text: "",
				},
				stackLabels: {
					enabled: true,
					style: {
						fontWeight: "bold",
						// color: ( // theme
						// 	Highcharts.defaultOptions.title.style &&
						// 	Highcharts.defaultOptions.title.style.color
						// ) || 'gray'
					},
				},
			},
			tooltip: {
				shared: true,
				valueSuffix: " units",
			},
			credits: {
				enabled: false,
			},
			plotOptions: {
				column: {
					stacking: "normal",
					dataLabels: {
						enabled: true,
					},
				},
			},
			series: this.props.dataSeries,
		});
	}

	componentDidMount() {
		console.log(this.props.categories, this.props.dataSeries);
		this.createColumnChart();
	}

	render() {
		return <div id="column-container" className="column-container"></div>;
	}
}

ColumnChart.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.string).isRequired,
	dataSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
};
