import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        position: 'absolute',
        margin: '0px',
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        maxWidth:theme.typography.pxToRem(1100)
    },
    dialogTitle: {
        paddingRight: '0px'
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    icons: {
        height: theme.typography.pxToRem(19),
        width: theme.typography.pxToRem(19)
    },
    titleText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: theme.typography.pxToRem(24),
        marginLeft:theme.typography.pxToRem(20),
        color: "#000000",
    },
    close:{
        marginLeft: 'auto',
        cursor:'pointer'
    },
    content:{
        display: 'flex',
        padding:0,
        justifyContent:'center'
    }
}))

export default useStyles