import axios from "./axios.global";

export const login = async (payload) => {
	try {
		let response = await axios.post("/login/", payload);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const resetPassword = async (payload) => {
	try {
		let response = await axios.post("/reset-password/", payload);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const getUser = async () => {
	try {
		let response = await axios.get("/users/");
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const searchUser = async (query) => {
	try {
		let response = await axios.get(`/users/?uname=${query}`);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const addUser = async (payload) => {
	try {
		let response = await axios.post("/users/", payload);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const getAllUser = async (uname) => {
	// let url = uname? "/users/?details=all": `/users/?uname=${uname}`;
	try {
		let response = await axios.get("/users/?details=all");
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const updateUser = async (id, payload) => {
	try {
		let response = await axios.put(`/users/?id=${id}`, payload);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const getNotifications = async (payload) => {
	try {
		let response = await axios.put("/users/", payload);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};

export const deleteUser = async (id) => {
	try {
		let response = await axios.delete(`/users/?id=${id}`);
		return response.data;
	} catch (err) {
		return err?.response?.data;
	}
};
