import React from "react";
import PropTypes from "prop-types";
import {
	Avatar,
	Card,
	Divider,
	Grid,
	IconButton,
	Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import gmail from "../../../assets/images/gmail.png";
// import whatsapp from "../../../assets/images/whatsapp.png";
import useStyles from "./styles";
import { whatsappIcon } from "../../../assets/images";
import CustomButton from "../../buttons/CustomButton";

const TeamCard = ({
	name,
	profilePhoto,
	contact,
	place,
	designation,
	salary,
	onEdit,
	onDelete,
	onPayment,
}) => {
	const {avataarContainer, avataar, padder, headerStyle, subHeaderStyle, imageWidth } =
		useStyles();

	const handleEdit = (e) => {
		onEdit(e);
	};

	const handleDelete = (e) => {
		onDelete(e);
	};

	return (
		<Card className={padder}>
			<Grid container spacing={3}>
				<Grid item xs={2} className={avataarContainer}>
					<Avatar
						className={avataar}
						variant="circular"
						src="/broken-image.jpg"
					>
						{profilePhoto}
					</Avatar>
				</Grid>
				<Grid item xs={10}>
					<Grid container>
						<Grid item xs={10}>
							<Typography variant="h5" className={headerStyle}>
								{name}
							</Typography>
						</Grid>
						<Grid item xs={1}>
							<IconButton onClick={handleEdit}>
								<EditIcon className={subHeaderStyle} />
							</IconButton>
						</Grid>
						<Grid item xs={1}>
							<IconButton onClick={handleDelete}>
								<ClearIcon className={subHeaderStyle} />
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" className={subHeaderStyle}>
								{place}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" className={subHeaderStyle}>
								Mobile: {contact}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h6" className={subHeaderStyle}>
								Designation: {designation}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h6" className={subHeaderStyle}>
								Salary: {salary}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Divider />
					<Grid container spacing={3} className={padder}>
						<Grid item xs={2} />
						<Grid xs={1}>
							<img src={gmail} className={imageWidth} />
						</Grid>
						<Grid xs={1}>
							<img src={whatsappIcon} className={imageWidth} />
						</Grid>
						<Grid item xs={4} />
						<Grid xs={3}>
							<CustomButton
								label={"Pay Now"}
								isOutlined
								onButtonClick={onPayment}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

TeamCard.propTypes = {};

export default TeamCard;
