import axios from "./axios.global";

export const generateActivity = async (payload) => {
	try {
		let response = await axios.post("/activity/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const generateActivityBulk = async (payload) => {
	try {
		let response = await axios.post(`/activity/bulk/`, payload, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllActivity = async (status) => {
	try {
		let api = `/activity/`;
		let response = await axios.get(api);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllActivityBulk = async (status) => {
	try {
		let api = `/activity/bulk/`;
		let response = await axios.get(api, {responseType: 'blob'});
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
}

export const editActivity = async (id, payload, status) => {
	try {
		let api = `/activity/${id}/`;
		let response = await axios.put(api, payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
}

export const deleteActivity = async (id, status) => {
	try {
		let api = `/activity/${id}/`;
		let response = await axios.delete(api);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
}