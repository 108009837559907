import Popup from "../../components/Popup";
import SimpleDropdown from "../../components/SimpleDropdown";
import { Grid, TextField, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { addRequest, getStates } from "../../apis/request.apis";
import { useEffect, useState } from "react";
import { getTasks } from "../../apis/inventory.apis";
import { editActivity } from "../../apis/activity.apis";

export default function ({title, handleClose, data }) {
	const { values, handleSubmit, handleBlur, handleChange, setFieldValue } =
		useFormik({
			initialValues: data,
			onSubmit: (values) => {
				editActivity(data.id, values)
					.then((res) => {console.log(res)
					handleClose();
					})
					.catch((err) => console.log(err));
			},
		});

		console.log({data});
	return (
		<Popup name={title} open={true} handleClose={handleClose}>
			<form
				style={{
					padding: "25px",
				}}
				onSubmit={handleSubmit}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							id="activity_id"
							variant="outlined"
							label="Activity Id"
							name="activity_id"
							fullWidth
							value={values.activity_id}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="activity_date"
							variant="outlined"
							name="activity_date"
							fullWidth
							type="date"
							value={values.activity_date}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="type"
							variant="outlined"
							label="Activity Type"
							name="type"
							fullWidth
							value={values.type}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="customer"
							variant="outlined"
							label="Customer"
							name="customer"
							fullWidth
							value={values.customer}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="site_id"
							variant="outlined"
							label="Site Id"
							name="site_id"
							fullWidth
							value={values.site_id}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="infra_id"
							variant="outlined"
							label="Infra Id"
							name="infra_id"
							fullWidth
							value={values.infra_id}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField
							id="sys_num"
							variant="outlined"
							label="System Number"
							name="sys_num"
							fullWidth
							value={values.sys_num}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="site_name"
							variant="outlined"
							label="Site Name"
							name="site_name"
							fullWidth
							value={values.site_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="circle"
							variant="outlined"
							label="Circle"
							name="circle"
							fullWidth
							value={values.circle}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="fse_name"
							variant="outlined"
							label="Fse Name"
							name="fse_name"
							fullWidth
							value={values.fse_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="cab_approved"
							variant="outlined"
							label="Cab Approved"
							name="cab_approved"
							fullWidth
							value={values.cab_approved}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="rate"
							variant="outlined"
							label="Rate"
							name="rate"
							fullWidth
							value={values.rate}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="total"
							variant="outlined"
							label="Total"
							name="total"
							fullWidth
							value={values.total}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="wcc_status"
							variant="outlined"
							label="Status"
							name="wcc_status"
							fullWidth
							value={values.status}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							size="large"
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		</Popup>
	);
}
