import React, { useEffect, useState } from "react";
import { Card, Chip, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BasicLayout from "../../components/BasicLayout";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import CustomButton from "../../components/buttons/CustomButton";
import { useHistory } from "react-router";
import { getAllInvoices, getInvoiceByID } from "../../apis/invoice.apis";
import HistoryIcon from "@mui/icons-material/History";
import { Pagination } from "@mui/material";
import TableCard from "../../components/cards/TableCard";
import axios from "axios";
import { createOrder, paymentStatus } from "../../apis/payment.apis";
import { getAllChallan } from "../../apis/dc.apis";
import DCTableIM from "../../apis/models/DCTableIM";
import { getAllBills } from "../../apis/bills.apis";
import BillsIM from "../../apis/models/BillsIM";
import { deleteActivity, getAllActivity, getAllActivityBulk } from "../../apis/activity.apis";
import ActivityIM from "../../apis/models/ActivityIM";
import EditActivityModal from "./EditActivityModal";
import { CSVLink } from "react-csv";

const ActivityHistoryPage = ({ loadPrimary }) => {
	const { content, invoiceCard, chipStyleRed, chipStyleGreen, chipGrid } =
		useStyles();
	const [data, setData] = useState([]);
	const [rawData, setRawData] = useState([]);
	const [editableData, setEditableData] = useState();
	const [edited, setEdited] = useState(false);
	const [showModal, setShowModal] = useState(false)


	// const user = useRecoilValue(currentUser);
	const notifications = useRecoilValue(userNotification);
	const history = useHistory();

	function loadScript(src) {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				console.log("on load");
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	const handlePayment = async (identity) => {
		const res = await loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		//RAZORPAY ORDER CREATION API
		const data = await getInvoiceByID(identity);
		const invoiceNo = data.invoice_no;
		const result = await createOrder({
			invoice_no: data.invoice_no,
			amount: data.total_amount,
		});

		if (!result) {
			alert("Server error. Are you online?");
			return;
		}

		// const amount = result.total_amount;
		// const invoiceNo = result.invoice_no;
		console.log({ result });
		const { amount, id, currency, api_key } = result.order_data;

		const options = {
			key: api_key, // Enter the Key ID generated from the Dashboard
			amount: amount.toString(),
			currency: currency,
			currency: "INR",
			name: "Rana Associates",
			description: "Bill Payment",
			// image: { logo },
			order_id: id,
			handler: async function (response) {
				console.log({ response });
				const data = {
					invoice_no: invoiceNo,
					// orderCreationId: id,
					amount: amount.toString(),
					payment_status: "Success",
					razorpay_payment_id: response.razorpay_payment_id,
					razorpay_order_id: response.razorpay_order_id,
					razorpay_signature: response.razorpay_signature,
					transaction_date: new Date(),
				};

				// RAZORPAY SUCCESS CREATION
				const result = await paymentStatus(data);

				alert("Payment Successful");
			},
			prefill: {
				name: "Rana Associates",
				email: "",
				contact: "9101960593",
			},
			notes: {
				address: "",
			},
			theme: {
				color: "#43425D",
			},
		};
		const paymentObject = new window.Razorpay(options);
		paymentObject.open();
	};

	useEffect(async () => {
		getAllActivity()
			.then((res) => {
				setRawData(res);
				console.log({res})
				const dataClass = res.map((curr) => new ActivityIM(curr));
				setData(dataClass);
				loadPrimary(false);
			})
			.catch((err) => console.log(err));
	}, [edited]);

	const handleInvoiceCard = (id) => {
		history.push(`/app/invoice/${id}`);
	};

	const handleFilter = () => {
		getAllInvoices("Pending")
			.then((res) => setData(res.data))
			.catch((err) => console.log({ err }));
	};

	console.log("in invoice history page");

	const handleDelete = (id) => {
		setEdited(false);
		deleteActivity(id).then((res) => setEdited(true)).catch(err => console.log(err))
	}

	const handleBulkDownload = () => {
		getAllActivityBulk().then(res => {
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Activity.csv`);
			document.body.appendChild(link);
			link.click();
		}).catch(err => console.log(err))
	}

	const handleEdit = (id) => {
		setEdited(false);
		console.log({ id })
		const currData = rawData.filter(curr => curr.activity_id === id)
		setEditableData(currData[0])
		setShowModal(true)
	}

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={3}>
				<Grid item xs={12}>
					<TableCard
						header={"All Activities"}
						columns={[
							"Id",
							"Activity Id",
							"Date",
							"Type",
							"Circle",
							"Site",
							"Zone",
							"Rate",
							"Cab Approved",
							"Total",
							"Status"
						]}
						rows={data}
						handleRowDelete={handleDelete}
						handleRowEdit={handleEdit}
						headerChild={
							<Grid item xs={2}>
								{/* <CustomButton
									label={"Bulk Download"}
									onButtonClick={handleBulkDownload}
								/> */}
															<CSVLink filename="Activities.csv" data={rawData} style={{padding: '1rem', color: '#43425d'}}>Download</CSVLink>

							</Grid>
						}
					/>
				</Grid>
				{/* <Grid item xs={10}>
					<Typography variant="h4">All Delivery Challan</Typography>
				</Grid>
				<Grid item xs={2}>
					<CustomButton label={"Filter"} onButtonClick={handleFilter} />
				</Grid>
				{data.map((invoice) => (
					<Grid item xs={12}>
						<Card
							className={invoiceCard}
							onClick={() => handleInvoiceCard(invoice.invoice_no)}
						>
							<Grid container>
								<Grid item xs={6}>
									<Typography variant="subtitle">
										{invoice.invoice_no}
									</Typography>
									<Typography variant="h6">
										Invoice To: {invoice.invoice_to.name}
									</Typography>
									<Typography variant="subtitle2">
										{invoice.invoice_date}
									</Typography>
								</Grid>
								<Grid item xs={4}></Grid>
								<Grid item xs={2} className={chipGrid}>
									<Chip
										className={
											invoice.status === "Pending"
												? chipStyleRed
												: chipStyleGreen
										}
										label={invoice.status}
										variant="outlined"
									/>
								</Grid> */}
				{/* <Grid item xs={1} className={chipGrid}>
									<CustomButton
										label={"Pay Now"}
										isOutlined
										onButtonClick={(e) => {
											e.stopPropagation();
											console.log("in button");
											handlePayment(invoice.invoice_no);
										}}
									/>
								</Grid> */}
				{/* </Grid>
						</Card> */}
				{/* </Grid>
				))} */}
				{/* <Grid item xs={8}></Grid>
				<Grid item xs={4}>
					<Pagination count={10} variant="outlined" color="primary" />
				</Grid> */}
				{showModal && (
					<EditActivityModal
						title={"Edit Activity"}
						data={editableData}
						handleClose={() => {
							setShowModal(false);
							setEdited(true);
						}}
					/>
				)}
			</Grid>
		</>
	);
};
ActivityHistoryPage.propTypes = {};

export default ActivityHistoryPage;
