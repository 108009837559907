export default class BillsIM {
	constructor(data) {
		this.id = data.id;
		this.location = data.location;
		this.bill = data.bill_no;
		this.date = data.date;
		this.amount = data.amount;
		this.file = data.file_link;
	}
}
