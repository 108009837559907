import { makeStyles } from "@material-ui/core";
import theme from "../../../theme";

export const useStyles = makeStyles({
	bordered: {
		width: "100%",
		justifyContent: "space-around",
		fontSize: theme.typography.pxToRem(18),
		border: "1px solid #43425d",
	},
	borderedNone: {
		paddingTop: theme.typography.pxToRem(6),
		paddingBottom: theme.typography.pxToRem(6),
		paddingLeft: theme.typography.pxToRem(20),
		paddingRight: theme.typography.pxToRem(20),
		width: "100%",
		color: "#D04A5C",
	},
	image: {
		maxWidth: "70%",
		[theme.breakpoints.up("1440")]: {
			height: theme.typography.pxToRem(23),
			width: theme.typography.pxToRem(23),
		},
		[theme.breakpoints.up("2000")]: {
			height: theme.typography.pxToRem(26),
			width: theme.typography.pxToRem(26),
		},
	},
	redText: {
		color: "white",
	},
	buttonStyle: {
		paddingTop: theme.typography.pxToRem(6),
		paddingBottom: theme.typography.pxToRem(6),
		paddingLeft: theme.typography.pxToRem(20),
		paddingRight: theme.typography.pxToRem(20),
		width: "80%",
		justifyContent: "space-around",
		fontSize: theme.typography.pxToRem(15),
		backgroundColor: "#A3A0FB !important",
		color: "white !important",
	},
});
