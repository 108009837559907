import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import useStyles from "./style";

const TextLogo = ({ textColor, variant }) => {
	const { logoText } = useStyles();
	return (
		<Typography className={logoText} style={{ color: textColor }} variant={variant}>
			RANA ASSOCIATES
		</Typography>
	);
};

TextLogo.propTypes = {};

export default TextLogo;
