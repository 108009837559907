import Popup from "../../components/Popup";
import { Grid, TextField, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { editConveyance } from "../../apis/conveyance.apis";

export default function ({title, handleClose, data }) {
	const { values, handleSubmit, handleBlur, handleChange, setFieldValue } =
		useFormik({
			initialValues: data,
			onSubmit: (values) => {
				editConveyance(data.id, values)
					.then((res) => {
						console.log(res);
						handleClose();
					})
					.catch((err) => console.log(err));
			},
		});

		console.log({data});
	return (
		<Popup name={title} open={true} handleClose={handleClose}>
			<form
				style={{
					padding: "25px",
				}}
				onSubmit={handleSubmit}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							id="activity_id"
							variant="outlined"
							label="Activity Id"
							name="activity_id"
							fullWidth
							value={values.activity_id}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="activity_type"
							variant="outlined"
							label="Activity Type"
							name="activity_type"
							fullWidth
							value={values.activity_type}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="advance"
							variant="outlined"
							label="Advance Paid"
							name="advance"
							fullWidth
							value={values.advance}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="date"
							variant="outlined"
							name="Date"
							fullWidth
							type="date"
							value={values.date}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="call_charge"
							variant="outlined"
							label="Call Charge"
							name="call_charge"
							fullWidth
							value={values.call_charge}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="call_no"
							variant="outlined"
							label="Call Number"
							name="call_no"
							fullWidth
							value={values.call_no}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="hotel"
							variant="outlined"
							label="Hotel Charge"
							name="hotel"
							fullWidth
							value={values.hotel}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="site_id"
							variant="outlined"
							label="Site Id"
							name="site_id"
							fullWidth
							value={values.site_id}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="location"
							variant="outlined"
							label="Location"
							name="location"
							fullWidth
							value={values.location}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField
							id="purchase"
							variant="outlined"
							label="Purchase"
							name="purchase"
							fullWidth
							value={values.purchase}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					
					<Grid item xs={12}>
						<TextField
							id="status"
							variant="outlined"
							label="Status"
							name="status"
							fullWidth
							value={values.status}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							size="large"
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		</Popup>
	);
}
