import React, { useEffect, useRef, useState } from "react";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BasicLayout from "../../components/BasicLayout";
import { useRecoilValue } from "recoil";
import { currentUser, userNotification } from "../../User.atom";
import InvoiceCard from "../../components/cards/InvoiceCard";
import { useHistory, useParams } from "react-router";
import { getInvoiceByID } from "../../apis/invoice.apis";
import CustomButton from "../../components/buttons/CustomButton";
import { jsPDF } from "jspdf";
import * as html2canvas from "html2canvas";
import InvoicePrintable from "../InvoicePrintable";
import { useBasicNav } from "../../utils/useBasicNav";
import { invoiceData } from "../../utils/constants";

const InvoiceDummy = ({ loadPrimary }) => {
	useBasicNav("invoices");
	const { content, container } = useStyles();
	const { id } = useParams();
	const layoutRef = useRef();
	const history = useHistory();

	const user = useRecoilValue(currentUser);
	const notifications = useRecoilValue(userNotification);

	useEffect(async () => {
		loadPrimary(false);
	}, []);

	const columns = [
		{ "Sl.no": "id" },
		{ Particulars: "item" },
		{ Quantity: "quantity" },
		{ "GST(in %)": "gst" },
		{ Rate: "rate" },
		{ Total: "total" },
	];


	const handlePrint = () => {
		// window.print();
		console.log(
			`${window.location.hostname.split("/")[0]}/app/print-invoice/${id}`
		);
		// window.open(`${window.location.hostname.split('/')[0]}/app/print-invoice/${id}`)
		history.push(`/app/print-invoice/${id}`);
		// window.open(`/app/print-invoice/${id}`)
	};

	const handleDownload = () => {
		window.scrollTo(0, 0);
		console.log("in download", layoutRef);

		html2canvas(layoutRef.current, {
			scale: 1,
			width: document.documentElement.scrollWidth,
			height: document.documentElement.scrollHeight,
		}).then((canvas) => {
			console.log("downloading");

			const imgData = canvas.toDataURL("image/jpeg");
			console.log("img", imgData);
			// let url = URL.createObjectURL(imgData);
			// let a = document.createElement("a");
			// a.href = imgData;
			// a.download = "title";
			// a.target = "_blank";
			// a.click();
			const pdf = new jsPDF();
			var width = pdf.internal.pageSize.getWidth();
			var height = pdf.internal.pageSize.getHeight();
			const widthRatio = width / canvas.width;
			const heightRatio = height / canvas.height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

			const canvasWidth = canvas.width * ratio;
			const canvasHeight = canvas.height * ratio;

			pdf.addImage(imgData, "JPEG", 10, 10, canvasWidth, canvasHeight);
			// pdf.output('dataurlnewwindow');
			pdf.save(`Invoice-${id}.pdf`);
		});
	};
	// useReactToPrint({
	// 	content: () => componentRef.current,
	// });

	return (
		<>
			<Box display="block" displayPrint="none" m={1}>
				<BasicLayout notifications={notifications} />
			</Box>
			<Grid
				container
				className={content}
				spacing={3}
				justifyContent="space-between"
			>
				<Box display="block" displayPrint="none" m={1}>
					<Grid item xs={2}>
						<Typography variant="h4">Invoices</Typography>
					</Grid>
				</Box>

				<Box display="block" displayPrint="none" m={1}>
					<Grid container spacing={3}>
						<Grid item xs={7}>
							{" "}
							<CustomButton
								label={"Print Preview"}
								onButtonClick={handlePrint}
							/>
						</Grid>
						<Grid item xs={5}>
							<CustomButton label={"Download"} onButtonClick={handleDownload} />
						</Grid>
					</Grid>
				</Box>

				<Grid item xs={12}>
					<Box displayPrint="block" width="100%" m={1}>
						{/* <Card className={container} ref={layoutRef}>
							<Grid item xs={12}>
								<Typography variant="h5" align="center">
									Invoice
								</Typography>
							</Grid>
						</Card> */}
						<InvoiceCard
							sellerName={invoiceData?.invoice_from?.name}
							address={`${invoiceData?.invoice_from?.city},${invoiceData?.invoice_from?.state}`}
							gstin={invoiceData?.invoice_from?.gstin}
							date={Date.now()}
							propetierName={"Arunudai Suppliers"}
							designation={"Propeiter"}
							id={"3w3232"}
							stats={"Pending"}
							columns={columns}
							details={invoiceData}
							rows={invoiceData?.goods}
							id={"section-to-print"}
							reference={layoutRef}
						/>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default InvoiceDummy;
