import axios from "./axios.global";

export const generateInvoice = async (payload) => {
	try {
		let response = await axios.post("/invoices/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getInvoiceByID = async (id) => {
	try {
		let response = await axios.get(`/invoices/${id}/`);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAllInvoices = async (status) => {
	try {
		let api = status ? `/invoices/?status=${status}` : "/invoices/";
		let response = await axios.get(api);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
