import { makeStyles } from "@material-ui/core";
import theme from "../../theme";

const useStyles = makeStyles({
	dropdown: {
		marginTop: theme.typography.pxToRem(53),
		marginLeft: theme.typography.pxToRem(-39),
		fontFamily: "Poppins",
		borderRadius: "0 !important",
	},

	// dropdownStyle: {
	// 	width: '100%'
	// },

	dropdownSideNav: {
		width: "46%",
		borderRadius: "0 !important",
		fontFamily: "Poppins",
		marginTop: theme.typography.pxToRem(30),
		marginLeft: theme.typography.pxToRem(-37),
	},
	paper: {
		border: "solid 10px transparent",
		borderRightColor: "#FFF",
		boxShadow: "0px 2px 10px #00000027",
		position: "absolute",
	},
});

export default useStyles;
