import React, { useEffect, useRef, useState } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BasicLayout from "../../components/BasicLayout";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import CustomButton from "../../components/buttons/CustomButton";
import { useHistory, useParams } from "react-router";
import { generateInvoice } from "../../apis/invoice.apis";
import { CSVLink } from "react-csv";
import { generateChallanBulk, generateChallanSingle } from "../../apis/dc.apis";
import InvoiceForm from "./InvoiceForm";
import sampleChallan from "../../utils/SampleChallan.xlsx";
import { useBasicNav } from "../../utils/useBasicNav";

const DCPage = ({ showToast, loadPrimary }) => {
	useBasicNav("delivery");
	const { content, productContainer, totalContainer } = useStyles();
	// const csvLink = useRef();
	const [data, setData] = useState({});
	const hiddenFileInput = useRef(null);
	const { type } = useParams();
	const notifications = useRecoilValue(userNotification);
	const history = useHistory();

	useEffect(() => {
		loadPrimary(false);
	}, []);

	const handleSave = () => {
		generateChallanSingle(data)
			.then((res) => showToast.success("Delivery Challan saved Successfully"))
			.catch((err) => console.log(err));
	};

	const handleBulkUpload = () => {
		console.log("in bulk");
		hiddenFileInput.current.click();
	};
	const handleChange = (event) => {
		console.log("in handleChange");
		const fileUploaded = event.target.files[0];
		console.log({ fileUploaded });
		let data = new FormData();
		data.append("file", fileUploaded);
		data.append("category", "Challan");

		console.log({ data });
		generateChallanBulk(data)
			.then((res) => showToast.success("File Uploaded Successfully"))
			.catch((err) => showToast.error("File couldn't be uploaded"));
	};

	const handleDownloadCsv = () => {
		window.location.href = sampleChallan;
	};

	const handleData = (payload) => {
		setData({ ...data, ...payload });
	};

	const handleHistory = () => {
		history.push("/app/delivery/history");
	};

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h4">Delivery Challan Entry</Typography>
				</Grid>
				<Grid item xs={2}>
					<CustomButton
						label={"Download Template"}
						onButtonClick={handleDownloadCsv}
					/>
				</Grid>
				<Grid item xs={2}>
					<CustomButton
						label={"Bulk Upload"}
						onButtonClick={handleBulkUpload}
					/>
					<input
						type="file"
						ref={hiddenFileInput}
						onChange={handleChange}
						style={{ display: "none" }}
					/>
				</Grid>
				<Grid item xs={2}>
					<CustomButton label={"History"} onButtonClick={handleHistory} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<InvoiceForm handleInvoiceDetail={handleData} />
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className={totalContainer}>
					<Grid container spacing={3}>
						<Grid item xs={8} />
						<Grid item xs={2}>
							<CustomButton label="Save Challan" onButtonClick={handleSave} />
						</Grid>
						<Grid item xs={2}>
							<CustomButton isOutlined label="Cancel" />
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</>
	);
};

DCPage.propTypes = {};

export default DCPage;
