import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import styles from "./styles";

const useStyles = makeStyles(styles);

const OverviewCard = ({
	header,
	id,
	num,
	textValue,
	image,
	onCardClick,
	increase,
	decrease,
}) => {
	const {
		bordered,
		outerCard,
		container,
		errorStyle,
		avataarStyle,
		innerCard,
		iconContainer,
		lowerGrid,
		iconButton,
		imageStyle,
		headerStyle,
		subHeaderStyle,
		headerContainer,
		success,
		flexGrid,
	} = useStyles();

	return (
		<Card className={container} onClick={() => onCardClick(id)}>
			<Grid
				container
				alignContent="center"
				className={outerCard}
				spacing={3}
				justifyContent="center"
			>
				<Grid item xs={12}>
					<Typography>{header}</Typography>
				</Grid>
				<Grid item xs={6}>
					<Grid item xs={12}>
						<Typography variant="h6" className={headerStyle}>
							{num}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{increase ? (
							<Grid className={flexGrid}>
								<ArrowUpwardIcon className={success} />
								<Typography variant="subtitle1" className={success}>
									{increase}
								</Typography>
							</Grid>
						) : (
							<Grid className={flexGrid}>
								<ArrowDownwardIcon className={errorStyle} />
								<Typography variant="subtitle1" className={errorStyle}>
									{decrease}{" "}
								</Typography>
							</Grid>
						)}
					</Grid>

					{/* </Grid> */}
				</Grid>
				<Grid item xs={6} className={imageStyle}>
					<img src={image} />
				</Grid>
			</Grid>
		</Card>
	);
};

OverviewCard.propTypes = {
	image: PropTypes.string,
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string.isRequired,
	menuItems: PropTypes.shape([
		{
			value: PropTypes.string,
			onClick: PropTypes.func,
		},
	]),
};

OverviewCard.defaultProps = {
	image: "",
	menuItems: [],
};

export default OverviewCard;
