import Highcharts from "highcharts";
import "highcharts/modules/exporting";
import { PureComponent, createRef, Component } from "react";
import "./style.css";
import PropTypes from "prop-types";

/**A component that renders a spline chart when provided with a few options */
export default class SplineChart extends Component {
	/**
	 *
	 * @param {Object} props
	 * @param {string[]} props.categories - The Labels on the x-axis for the spline chart
	 * @param {number[]} props.productSentData - An array representing of number of units shipped for each label on x-axis
	 * @param {number[]} props.productReqData -  An array representing of number of units requested for each label on x-axis
	 */
	constructor(props) {
		super(props);
	}

	createSplineChart() {
		Highcharts.chart("spline-container", {
			chart: {
				type: "areaspline",
			},
			colors: ["#A3A0FB", "#54D8FF", "#62e3a3"],
			title: {
				text: "",
			},
			legend: {
				align: "left",
				squareSymbol: true,
			},
			xAxis: {
				categories: this.props.categories,
			},
			yAxis: {
				labels: {
					format: "₹{value}",
				},
				title: "",
			},
			tooltip: {
				shared: true,
				valueSuffix: " units",
			},
			credits: {
				enabled: false,
			},
			plotOptions: {
				areaspline: {
					fillOpacity: 0.2,
				},
			},
			series: [
				{
					name: "Credited Amount",
					data: this.props.productSentData,
				},
				{
					name: "Debited Amount",
					data: this.props.productReqData,
				},
				{
					name: "Net Profit",
					data: this.props.profitData,
				},
			],
		});
	}

	componentDidMount() {
		console.log(
			this.props.categories,
			this.props.productReqData,
			this.props.profitData
		);
		this.createSplineChart();
	}

	render() {
		return <div id="spline-container" className="spline-container"></div>;
	}
}

SplineChart.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.string).isRequired,
	productSentData: PropTypes.arrayOf(PropTypes.number).isRequired,
	productReqData: PropTypes.arrayOf(PropTypes.number).isRequired,
	profitData: PropTypes.arrayOf(PropTypes.number).isRequired,
};
