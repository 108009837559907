import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Avatar,
	Card,
	Chip,
	Divider,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	SvgIcon,
	TextField,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ReactToPrint from "react-to-print";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import AddIcon from "@mui/icons-material/Add";
import styles from "./styles";
import CustomButton from "../../buttons/CustomButton";
import TableCard from "../TableCard";
import SimpleDropdown from "../../SimpleDropdown";
import SaveIcon from "@material-ui/icons/Save";
// import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomEditableTable from "../../CustomEditableTable";
// import { addIcon } from "../../../assets/images";
import NumberFormatter from "../../../utils/NumberFormatter";
import DisplayTable from "../../tables/DisplayTable";
import { invoiceData } from "../../../utils/constants";

const useStyles = makeStyles(styles);

const InvoiceCard = ({
	sellerName,
	address,
	columns,
	date,
	propetierName,
	designation,
	id,
	stats,
	details,
	rows,
	gstin,
	reference,
}) => {
	const {
		bordered,
		outerCard,
		container,
		tableHeader,
		tableGrid,
		avataarStyle,
		innerCard,
		iconContainer,
		titleStyle,
		lowerGrid,
		iconButton,
		imageStyle,
		headerStyle,
		subHeaderStyle,
		headerContainer,
		dividerContainer,
		paddedGrid,
	} = useStyles();

	const [otherDetails, setOtherDetails] = useState({});
	const [invoiceTo, setInvoiceTo] = useState({});
	const [invoiceFrom, setInvoiceFrom] = useState({});
	const [subtotal, setSubTotal] = useState();
	const [gst, setTotalGst] = useState();
	const [total, setTotal] = useState();
	let rowId = 0;

	useEffect(() => {
		let totalAmount = 0;
		let gstAmount = 0;
		rows?.map((row) => {
			const totalRate = row.quantity * row.rate;
			totalAmount = totalAmount + totalRate;
			const gst = row.gst / 100;
			gstAmount = gstAmount + totalRate * gst;
		});
		setSubTotal(totalAmount);
		setTotalGst(NumberFormatter.roundOff(gstAmount, 2));
		setTotal(NumberFormatter.roundOff(totalAmount + gstAmount, 2));
	}, [rows]);

	return (
		<>
			<Card
				className={container}
				style={{ border: "1px solid" }}
				ref={reference}
			>
				<Grid
					container
					alignContent="center"
					className={outerCard}
					spacing={5}
					style={{ border: "1px solid" }}
				>
					<Grid container justifyContent="space-between">
						<Grid item xs={5}>
							<Typography variant="h4">{sellerName}</Typography>
						</Grid>
						<Grid item xs={1}>
							<Typography variant="h4">Invoice</Typography>
						</Grid>
					</Grid>
					<Grid container justifyContent="space-between">
						<Grid item xs={3}>
							<Typography variant="h6">{address}</Typography>
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
					<Grid item xs={12} className={dividerContainer}>
						{/* <Divider /> */}
					</Grid>

					<Grid container justifyContent="space-between">
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
							}}
						>
							<Typography variant="h6">
								<b>Details of Receiver(Bill To)</b>
							</Typography>
							Firm/Association Name:{" "}
							<Typography>{details?.invoice_to.name}</Typography>
							City/Town:
							<Typography>{details?.invoice_to.city}</Typography>
							State:
							<Typography>{details?.invoice_to.state}</Typography>
							GSTIN:
							<Typography>{details?.invoice_to.gstin}</Typography>
						</Grid>
						<Grid container xs={6}>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Invoice no.:</Typography>
								<Typography>{details?.invoice_no}</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography> Dated:</Typography>
								<Typography>{details?.invoice_date}</Typography>
							</Grid>
							{/* <Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								Invoice Type:
								<Typography>{details?.type}</Typography>
							</Grid> */}
							{/* <Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								State:
								<Typography>{details?.state}</Typography>
							</Grid> */}

							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Delivery Note</Typography>
								<Typography>{details?.delivery_note}</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Mode/ Terms of Payment</Typography>
								<Typography>{details?.mode_of_payment}</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Reference No./ Date</Typography>
								<Typography>{details?.suppliers_reference}</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Order Reference</Typography>
								<Typography>{details?.other_references}</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "grid",
								border: "1px solid",
								paddingLeft: "6px",
							}}
						>
							<Typography variant="h6">
								<b>Details of consignee(Ship To)</b>{" "}
							</Typography>
							Firm/Association Name:{" "}
							<Typography>{details?.shipping_details.name}</Typography>
							City/Town:
							<Typography>{details?.shipping_details.city}</Typography>
							State:
							<Typography>{details?.shipping_details.state}</Typography>
							GSTIN:
							<Typography>{details?.shipping_details.gstin}</Typography>
						</Grid>
						<Grid container xs={6}>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Buyer's Order No.</Typography>
								<Typography>
									{details?.buyers_details.buyer_order_no}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Dated</Typography>
								<Typography>{details?.buyers_details.buyer_date}</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Dispatch Doc No.</Typography>
								<Typography>
									{details?.buyers_details.dispatch_doc_no}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Delivery Note Date</Typography>
								<Typography>
									{details?.buyers_details.delivery_note_date}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Dispatched through</Typography>
								<Typography>
									{details?.buyers_details.dispaatchhed_through}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Destination</Typography>
								<Typography>{details?.buyers_details.destination}</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								<Typography>Terms of delivery</Typography>
								<Typography>
									{details?.buyers_details.terms_of_delivery}
								</Typography>
							</Grid>

							{/* <Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								State Code:
								<Typography>{details?.stateCode}</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								Project Zone:
								<Typography>{details?.pzone}</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								HSN/SAC:
								<Typography>{details?.hsncode}</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: "grid",
									border: "1px solid",
									paddingLeft: "6px",
								}}
							>
								Status:
								<Typography>{details?.status}</Typography>
							</Grid> */}
						</Grid>
					</Grid>

					<Grid xs={12} style={{ border: "1px solid" }}>
						<DisplayTable columns={columns} rows={rows} />
					</Grid>

					<Grid item xs={8} className={paddedGrid}>
						<Typography variant="h6">
							<b>Account Details</b>{" "}
						</Typography>

						<Typography>
							<b>A/c Holder Name:</b> {details?.acc_details?.account_holder}
						</Typography>
						<Typography>
							<b>Bank Name:</b> {details?.acc_details?.bank_name}
						</Typography>
						<Typography>
							<b>A/c No.:</b> {details?.acc_details?.acc_no}
						</Typography>
						<Typography>
							<b>Branch:</b> {details?.acc_details?.branch}
						</Typography>
						<Typography>
							<b>GSTIN:</b> {gstin}
						</Typography>
						<Typography>
							<b>IFSC:</b> {details?.acc_details?.ifsc}
						</Typography>

						<Typography>
							<b>PAN Code:</b> {details?.acc_details.pan}
						</Typography>
					</Grid>
					<Grid item xs={4} className={paddedGrid} justifyContent="center">
						<Grid item xs={12}>
							<Typography variant="h6">
								<b>Sub-total:</b>{" "}
								{new Intl.NumberFormat("en-IN", {
									style: "currency",
									currency: "INR",
								}).format(subtotal)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{details?.invoice_to?.state === "Assam" ? (
								<>
									<Typography variant="h6">
										<b>SGST:</b>{" "}
										{new Intl.NumberFormat("en-IN", {
											style: "currency",
											currency: "INR",
										}).format(gst / 2)}
									</Typography>

									<Typography variant="h6">
										<b>CGST:</b>{" "}
										{new Intl.NumberFormat("en-IN", {
											style: "currency",
											currency: "INR",
										}).format(gst / 2)}
									</Typography>
								</>
							) : (
								<>
									<Typography variant="h6">
										<b>IGST:</b>{" "}
										{new Intl.NumberFormat("en-IN", {
											style: "currency",
											currency: "INR",
										}).format(gst)}
									</Typography>
								</>
							)}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">
								<b>
									INR{" "}
									{new Intl.NumberFormat("en-IN", {
										style: "currency",
										currency: "INR",
									}).format(total)}{" "}
								</b>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">
								<b>
									Paid :
									{new Intl.NumberFormat("en-IN", {
										style: "currency",
										currency: "INR",
									}).format(invoiceData.paid)}{" "}
								</b>
							</Typography>
						</Grid>
						<Grid item xs={12}>

							<Typography variant="h5">
								<b>
									Pending : 
									{new Intl.NumberFormat("en-IN", {
										style: "currency",
										currency: "INR",
									}).format(total - invoiceData.paid)}{" "}
								</b>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<b>(Rupees. {details?.total_amount_in_words})</b>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</>
	);
};

InvoiceCard.propTypes = {
	image: PropTypes.string,
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string.isRequired,
	menuItems: PropTypes.shape([
		{
			value: PropTypes.string,
			onClick: PropTypes.func,
		},
	]),
};

InvoiceCard.defaultProps = {
	image: "",
	menuItems: [],
};

export default InvoiceCard;
