export default (theme) => ({
  containerStyle: {
    width: "100%",
    border: "1px solid #14004750",
    borderRadius: theme.typography.pxToRem(10),
    padding: "0px !important",
    // paddingRight: `${theme.typography.pxToRem(46)} !important`,
    // paddingLeft: `${theme.typography.pxToRem(32)} !important`,
    // height: theme.typography.pxToRem(42),
  },

  outlinedRootStyle: {
    padding: "0px !important",
  },
  noBorder: {
    border: "none",
  },

  adornmentStyle: {
    height: "2.5em",
    width: "5em",
    justifyContent: "center",
    background: "#EFF8F9",
    borderRadius: "10px",
    maxHeight: "none",
  },

  menuStyle: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
    borderRadius: theme.typography.pxToRem(10),
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "0px !important",
  },
  menuList: {
    margin: theme.typography.pxToRem(16),
  },
  menuItemStyle: {
    justifyContent: "space-between",
    background: "none !important",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.25)",
    "&:hover": {
      background: "none",
    },
  },
  menuItemIcon: {
    minWidth: "0px",
    color: "black",
  },
  buttonContainer: {
    marginTop: theme.typography.pxToRem(25),
    // paddingTop: theme.typography.pxToRem(20),
    // paddingBottom: theme.typography.pxToRem(20),
  },

  formLabelFocused: {
    borderRadius: theme.typography.pxToRem(10)
  }
});
