import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LogoIcon from "../../icons/svgs/techvariable_icon.svg";
import useStyles from "./style";
import {
	Button,
	Checkbox,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

import theme from "../../theme";
import { useBasicNav } from "../../utils/useBasicNav";
import { useFormik } from "formik";
import { resetPassword } from "../../apis/user.apis";
import {
	resetPersistentAuthentication,
	setPersistentAuthentication,
} from "../../utils/functions";
import { authIcon } from "../../assets/images";
import CustomButton from "../../components/buttons/CustomButton/CustomButton";

const PasswordLinkPage = ({ showToast, hideToast, loadPrimary }) => {
	const {
		logoText,
		logo,
		imageContainer,
		imageStyle,
		formContainer,
		subTitleText,
		textCentered,
	} = useStyles();

	const { setActiveTab } = useBasicNav();

	useEffect(() => {
		resetPersistentAuthentication();
		loadPrimary(false);
	}, []);

	const { values, errors, handleChange, handleSubmit } = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: (values) => {
			resetPassword(values)
				.then((response) => {
					if (response.status != 200) return showToast.error(response.messageṇ);
					showToast.success(response.message);
				})
				.catch((err) => {
					showToast.error(err.message);
				});
		},
	});

	const submit = (e) => e.key == "Enter" && handleSubmit();

	const [checked, setChecked] = useState(true);

	return (
		<Grid container justify="center" alignItems="center">
			<Grid item xs={6} className={imageContainer}>
				<img src={authIcon} className={imageStyle} />
			</Grid>
			<Grid item xs={6}>
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					className={formContainer}
					spacing={3}
				>
					<Grid item xs={12}>
						<Typography className={logoText} variant="h4">
							RANA ASSOCIATES
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="subtitle" className={subTitleText}>
							Enter your email to receive a password reset link.
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Grid container spacing={5}>
							<Grid item xs={12}>
								<TextField
									name="email"
									fullWidth
									value={values.email}
									onChange={handleChange}
									placeholder="Email"
								/>
							</Grid>
							<Grid container justifyContent="space-around">
								<Grid item xs={6}>
									<CustomButton
										label={"Send Request"}
										onButtonClick={handleSubmit}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

PasswordLinkPage.propTypes = {
	// As discussed, will be decided later
};

export default PasswordLinkPage;
