import axios from "./axios.global";

export const addRequest = async (payload) => {
	try {
		let response = await axios.post("/orders/request/", payload);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getStates = async () => {
	try {
		let response = await axios.get("/states/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getDistricts = async () => {
	try {
		let response = await axios.get("/districts/");
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};
