import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Card,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	OutlinedInput,
	Typography,
	Button,
} from "@material-ui/core";
import useStyles from "./styles";
import TeamCard from "../../components/cards/TeamCard/TeamCard";
import BasicLayout from "../../components/BasicLayout";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddTeamMember from "./AddTeamMember";
import { deleteUser, getAllUser, searchUser } from "../../apis/user.apis";
import { useRecoilValue } from "recoil";
import { userNotification } from "../../User.atom";
import { createOrder } from "../../apis/payroll.apis";

const TeamPage = ({ showToast, loadPrimary }) => {
	const {
		tableHeader,
		content,
		searchStyle,
		cardStyle,
		productLayout,
		tableCard,
		starIcon,
		divider,
		quickDetail,
		chartCard,
		chartContainer,
		avataar,
		iconStyle,
	} = useStyles();

	const [team, setTeam] = useState([]);
	const notifications = useRecoilValue(userNotification);
	const [reloadData, setReloadData] = useState(false);
	const [editableData, setEditableData] = useState();
	const [isEditable, setIsEditable] = useState(false);

	// const team = [
	// 	{ name: "Ankita", contact: "435432424332", place: "Guwahati" },
	// 	{ name: "Ankita", contact: "435432424332", place: "Guwahati" },
	// 	{ name: "Ankita", contact: "435432424332", place: "Guwahati" },
	// 	{ name: "Ankita", contact: "435432424332", place: "Guwahati" },
	// 	{ name: "Ankita", contact: "435432424332", place: "Guwahati" },
	// 	{ name: "Ankita", contact: "435432424332", place: "Guwahati" },
	// ];

	const [modalOpen, setModalOpen] = useState(false);

	const handleChange = async (e) => {
		console.log("e", e.target.value);
		if (e.target.value) {
			const users = await searchUser(e.target.value);
			setTeam(users.data);
			loadPrimary(false);
		} else {
			const users = await getAllUser();
			setTeam(users.data);
			loadPrimary(false);
		}
	};

	// useEffect(async () => {
	// 	const users = await getAllUser();
	// 	setTeam(users.data);
	// 	loadPrimary(false);
	// }, []);

	useEffect(async () => {
		const users = await getAllUser();
		setTeam(users.data);
		loadPrimary(false);
	}, []);

	useEffect(async () => {
		const users = await getAllUser();
		setTeam(users.data);
	}, [reloadData]);

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			//hit search
		}
	};

	const handleEdit = (e, member) => {
		console.log({ member });
		setIsEditable(true);
		setEditableData(member);
		setModalOpen(true);
	};

	const handleDelete = (e, id) => {
		deleteUser(id)
			.then((res) => {
				setReloadData(!reloadData);
				console.log(res);
				showToast.success("User Deleted Successfully");
			})
			.catch((err) => showToast.error("User could not be deleted"));
	};

	const handlePayment = (e, member) => {
		const payload = {
			customer_details: {
				customer_id: `${member.first_name}${member.phone}`,
				customer_email: member.email,
				customer_phone: member.phone,
				customer_bank_account_number: member.accN0,
				customer_bank_ifsc: member.ifsc,
			},
			username: member.username,
			order_amount: member.salary,
		};

		createOrder(payload)
			.then((res) => {
				console.log(res);
				console.log("url", res.order_data.payment_link);
				window.open(res.order_data.payment_link);
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={1}>
				<Grid item xs={6}>
					<Typography variant="h4">Team Members</Typography>
				</Grid>
				<Grid item xs={5}>
					<FormControl fullWidth variant="outlined">
						<OutlinedInput
							id="outlined-adornment-amount"
							placeholder="Search Name..."
							onChange={handleChange}
							className={searchStyle}
							onKeyDown={handleKeyDown}
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon className={iconStyle} />
								</InputAdornment>
							}
							// labelWidth={60}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={1}>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						size="large"
						style={{ height: "100%" }}
						title="Add Team Member"
						onClick={() => setModalOpen(true)}
					>
						<PersonAddIcon style={{ fontSize: "24px" }} />
					</Button>
				</Grid>
			</Grid>
			<Grid container className={content} spacing={3}>
				{team?.map((member) => (
					<Grid item xs={6}>
						<TeamCard
							name={`${member.first_name} ${member.last_name}`}
							contact={member.phone}
							designation={member.role}
							salary={member.salary}
							place={"Guwahati"}
							onEdit={(e) => handleEdit(e, member)}
							onDelete={(e) => handleDelete(e, member.id)}
							onPayment={(e) => handlePayment(e, member)}
						/>
					</Grid>
				))}

				<Grid item xs={8}></Grid>
				{/* <Pagination count={1} shape="rounded" /> */}
			</Grid>
			{modalOpen && (
				<AddTeamMember
					isEditable={isEditable}
					editableData={editableData}
					handleClose={() => {
						if (isEditable) {
							setIsEditable(false);
							setEditableData();
						}
						setModalOpen(false);
						setReloadData(!reloadData);
					}}
				/>
			)}
		</>
	);
};

TeamPage.propTypes = {};

export default TeamPage;
