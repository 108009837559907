import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
	tabs: {
		// '& button span': {
		//  color:'#666666',
		//  textTransform:'none',
		//  fontSize:'14px'
		// },
	},
	indicator: {
		backgroundColor: "#FFDE29 ",
		height: theme.typography.pxToRem(6),
	},
	topSection: {
		background: "#140047",
		borderRadius: "10px 10px 0px 0px",
		[theme.breakpoints.down("800")]: {
			minHeight: theme.typography.pxToRem(20),
			height: theme.typography.pxToRem(80),
		},
		[theme.breakpoints.up("2000")]: {
			paddingTop: theme.typography.pxToRem(17),
			paddingLeft: theme.typography.pxToRem(17),
			paddingRight: theme.typography.pxToRem(17),
		},
	},
	selectedTab: {
		color: "#FFDE29 !important",
		// marginLeft:theme.typography.pxToRem(10),
		// marginRight:theme.typography.pxToRem(10)
	},
	tab: {
		color: "#ffffff",
		fontFamily: "Poppins",
		textTransform: "none",
		fontSize: theme.typography.pxToRem(19),
		minWidth: theme.typography.pxToRem(60),
		width: "50%",
		//marginRight:'20px'
	},
	tableContainer: {
		maxHeight:'100vh',
		overflowY: "auto",
	},
}));
export default useStyles;
