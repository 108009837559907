import Popup from "../../components/Popup";
import SimpleDropdown from "../../components/SimpleDropdown";
import { Grid, TextField, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { addRequest, getStates } from "../../apis/request.apis";
import { useEffect, useState } from "react";
import { getTasks } from "../../apis/inventory.apis";

/**
 *
 * @param {Object} param
 * @param {(props : any) => void} param.handleClose
 * @param {('Return' | 'Replacement')} param.orderType
 * @param {string} param.title
 * @returns
 */
export default function ({ handleClose, orderType, title }) {
	const [installationMenu, setInstallationMenu] = useState([]);
	const [statesMenu, setStatesMenu] = useState([]);
	const initValues = {
		description: "",
		order_name: "",
		installation_type: "",
		order_type: orderType,
		customer_name: "",
		mobile: "",
		email: "",
		address: "",
		pincode: "",
		district: "",
		state: "",
	};

	const { values, handleSubmit, handleBlur, handleChange, setFieldValue } =
		useFormik({
			initialValues: initValues,
			onSubmit: (values) => {
				addRequest(values)
					.then((res) => console.log(res))
					.catch((err) => console.log(err));
			},
		});

	useEffect(async () => {
		const tasks = await getTasks();
		setInstallationMenu(tasks.data);
		const states = await getStates();
		setStatesMenu(states.data);
	}, []);

	return (
		<Popup name={title} open={true} handleClose={handleClose}>
			<form
				style={{
					padding: "25px",
				}}
				onSubmit={handleSubmit}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							id="description"
							variant="outlined"
							label="Description"
							name="description"
							fullWidth
							value={values.description}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="order_name"
							variant="outlined"
							label="Order Name"
							name="order_name"
							fullWidth
							value={values.order_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<SimpleDropdown
							defaultValue=""
							variant="outlined"
							id="installation_type"
							label="Installation Type"
							onChange={(e) => {
								setFieldValue("installation_type", e.target.value);
							}}
							menuItem={installationMenu}
						/>
					</Grid>
					<Grid item xs={6}>
						<SimpleDropdown
							defaultValue={orderType}
							variant="outlined"
							id="order_type"
							label="Order Type"
							onChange={(e) => {
								setFieldValue("order_type", e.target.value);
							}}
							menuItem={["Return", "Replacement"]}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="customer_name"
							variant="outlined"
							label="Customer Name"
							name="customer_name"
							fullWidth
							value={values.customer_name}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="address"
							variant="outlined"
							label="Address"
							name="address"
							fullWidth
							value={values.address}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="mobile"
							variant="outlined"
							label="Mobile Number"
							name="mobile"
							fullWidth
							value={values.mobile}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="email"
							variant="outlined"
							label="Email"
							name="email"
							fullWidth
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField
							id="pincode"
							variant="outlined"
							label="PIN Code"
							name="pincode"
							fullWidth
							value={values.pincode}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Grid>
					{/* <Grid item xs={6}>
						<SimpleDropdown
							defaultValue=""
							variant="outlined"
							label="District"
							id="district"
							onChange={(e) => {
								setFieldValue("district", e.target.value);
							}}
							menuItem={["Option 1", "Option 2"]}
						/>
					</Grid> */}
					<Grid item xs={6}>
						<SimpleDropdown
							defaultValue=""
							variant="outlined"
							label="State"
							id="state"
							onChange={(e) => {
								setFieldValue("state", e.target.value);
							}}
							menuItem={statesMenu}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							size="large"
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		</Popup>
	);
}
