import * as React from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
	useGridApiRef,
	DataGridPro,
	// GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import EditToolbar from "./EditToolbar";
import { IconButton } from "@material-ui/core";
// import { GridActionsCellItem } from '@mui/x-data-grid';
// import {
//   randomCreatedDate,
//   randomTraderName,
//   randomUpdatedDate,
//   randomId,
// } from '@mui/x-data-grid-generator';
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

const defaultTheme = createTheme();

const useStyles = makeStyles(
	(theme) => ({
		actions: {
			color: theme.palette.text.secondary,
		},
		textPrimary: {
			color: theme.palette.text.primary,
		},
	}),
	{ defaultTheme }
);

// const rows = [
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 25,
//     dateCreated: randomCreatedDate(),
//     lastLogin: randomUpdatedDate(),
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 36,
//     dateCreated: randomCreatedDate(),
//     lastLogin: randomUpdatedDate(),
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 19,
//     dateCreated: randomCreatedDate(),
//     lastLogin: randomUpdatedDate(),
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 28,
//     dateCreated: randomCreatedDate(),
//     lastLogin: randomUpdatedDate(),
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 23,
//     dateCreated: randomCreatedDate(),
//     lastLogin: randomUpdatedDate(),
//   },
// ];

const CustomEditableTable = ({ tableColumns, rows, onRowChange }) => {
	const handleChange = (e) => {
		console.log({ e });
	};
	// const classes = useStyles();
	// const apiRef = useGridApiRef();

	// const handleRowEditStart = (params, event) => {
	// 	event.defaultMuiPrevented = true;
	// };

	// const handleRowEditStop = (params, event) => {
	// 	event.defaultMuiPrevented = true;
	// };

	// const handleCellFocusOut = (params, event) => {
	// 	event.defaultMuiPrevented = true;
	// };

	// const handleEditClick = (id) => (event) => {
	// 	event.stopPropagation();
	// 	apiRef.current.setRowMode(id, "edit");
	// };

	// const handleSaveClick = (id) => (event) => {
	// 	event.stopPropagation();
	// 	apiRef.current.commitRowChange(id);
	// 	apiRef.current.setRowMode(id, "view");

	// 	const row = apiRef.current.getRow(id);
	// 	apiRef.current.updateRows([{ ...row, isNew: false }]);
	// };

	// const handleDeleteClick = (id) => (event) => {
	// 	event.stopPropagation();
	// 	apiRef.current.updateRows([{ id, _action: "delete" }]);
	// };

	// const handleCancelClick = (id) => (event) => {
	// 	event.stopPropagation();
	// 	apiRef.current.setRowMode(id, "view");

	// 	const row = apiRef.current.getRow(id);
	// 	if (row.isNew) {
	// 		apiRef.current.updateRows([{ id, _action: "delete" }]);
	// 	}
	// };

	// const columns = [
	// 	...tableColumns,
	// 	{
	// 		field: "actions",
	// 		type: "actions",
	// 		headerName: "Actions",
	// 		width: 100,
	// 		cellClassName: classes.actions,
	// 		getActions: ({ id }) => {
	// 			const isInEditMode = apiRef.current.getRowMode(id) === "edit";

	// 			if (isInEditMode) {
	// 				return [
	// 					<IconButton
	// 						icon={<SaveIcon />}
	// 						label="Save"
	// 						onClick={handleSaveClick(id)}
	// 						color="primary"
	// 					/>,
	// 					<IconButton
	// 						icon={<CancelIcon />}
	// 						label="Cancel"
	// 						className={classes.textPrimary}
	// 						onClick={handleCancelClick(id)}
	// 						color="inherit"
	// 					/>,
	// 				];
	// 			}

	// 			return [
	// 				<IconButton
	// 					icon={<EditIcon />}
	// 					label="Edit"
	// 					className={classes.textPrimary}
	// 					onClick={handleEditClick(id)}
	// 					color="inherit"
	// 				/>,
	// 				<IconButton
	// 					icon={<DeleteIcon />}
	// 					label="Delete"
	// 					onClick={handleDeleteClick(id)}
	// 					color="inherit"
	// 				/>,
	// 			];
	// 		},
	// 	},
	// ];

	return (
		<div style={{ height: 200, width: "100%" }}>
			<DataGrid
				rows={rows}
				columns={tableColumns}
				rowHeight={38}
				disableSelectionOnClick
				disableColumnMenu
				// onCellValueChange={handleChange}
				onStateChange={handleChange}
				hideFooterPagination
				hideFooter
			/>
		</div>
	);
};

export default CustomEditableTable;

CustomEditableTable.propTypes = {
	tableColumns: PropTypes.shape([]),
};
