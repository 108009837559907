import React, { useEffect, useState } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BasicLayout from "../../components/BasicLayout";
import OverviewCard from "../../components/cards/OverviewCard/OverviewCard";
import TableCard from "../../components/cards/TableCard";
import DropdownMenu from "../../components/DropdownMenu";
import SimpleDropdown from "../../components/SimpleDropdown";
import SplineChart from "../../components/SplineChart";
import NEMapChart from "../../components/NEMapChart";
import { useRecoilValue } from "recoil";
import { currentUser, userNotification } from "../../User.atom";
import {
	getSplineChart,
	getHeader,
	getMapChart,
} from "../../apis/dashboard.apis";
import { barBlue, barGreen, barPurple } from "../../assets/images";
import { getAllInvoices } from "../../apis/invoice.apis";
import InvoiceTableIM from "../../apis/models/InvoiceTableIM";
import WithLoader from "../../hoc/WithLoader";
import { useBasicNav } from "../../utils/useBasicNav";

const DashboardPage = ({ loadPrimary }) => {
	const {
		tableHeader,
		content,
		cardStyle,
		productLayout,
		tableCard,
		starIcon,
		divider,
		quickDetail,
		chartCard,
		chartContainer,
	} = useStyles();
	useBasicNav("dashboard");
	const [dashboard, setDashboard] = useState({});

	const [spline, setSpline] = useState();

	const [mapData, setMapData] = useState();

	const [option, setOption] = useState("Last_1_year");

	const [isLoading, setIsLoading] = useState(false);

	const [invoices, setInvoices] = useState([]);

	const overviewCard = [
		{ header: "Total Stock", num: "767878", increase: "23.8" },
		{ header: "Total Stock", num: "767878", decrease: "23.8" },
		{ header: "Total Stock", num: "767878", increase: "23.8" },
	];

	const notifications = useRecoilValue(userNotification);

	const handleChange = (e) => {
		setIsLoading(true);
		setOption(e.target.value);
	};

	const imageBar = [barBlue, barGreen, barPurple];
	const SplineChartWithLoader = WithLoader(SplineChart);

	useEffect(() => {
		setIsLoading(true)
		getSplineChart(option)
			.then((res) => {
				setSpline(res.data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log({ err });
				setIsLoading(false);
			});
	}, [option]);

	useEffect(() => {
		getHeader()
			.then((res) => {
				setDashboard(res.data);
			})
			.catch((err) => console.log({ err }));
		getSplineChart(option)
			.then((res) => setSpline(res.data))
			.catch((err) => console.log({ err }));
		getAllInvoices("Pending")
			.then((res) => {
				const data = res.data.map((curr) => new InvoiceTableIM(curr));
				setInvoices(data);
			})
			.catch((err) => console.log(err));
		getMapChart("Challan")
			.then((res) => {
				setMapData(res.data);
				loadPrimary(false);
			})
			.catch((err) => {
				console.log({ err });
			});
		// getSplineChart(option)
		// 	.then((res) => {
		// 		setSpline(res);
		// 		loadPrimary(false);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 		loadPrimary(false);
		// 	});
	}, []);

	console.log({ invoices });

	console.log("dash", dashboard, spline);
	return (
		<>
			<BasicLayout notifications={notifications} />
			<Grid container className={content} spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4">Overview</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						{Object.keys(dashboard).map((key, index) => (
							<Grid item xs={12} md={4}>
								<OverviewCard
									header={key}
									num={dashboard[key]}
									// increase={data.Increase && data.Increase}
									// decrease={data.Decrease && data.Decrease}
									image={imageBar[index]}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid item xs={12} className={chartContainer}>
					<Card className={chartCard}>
						<Grid container>
							<Grid item xs={9}>
								<Typography variant="h4">Statistics</Typography>
							</Grid>
							<Grid item xs={3}>
								<SimpleDropdown
									menuItem={[
										{ title: "Last 1 year", key: "Last_1_year" },
										{ title: "Last 6 months", key: "Last_6_months" },
										{ title: "Last 1 month", key: "Last_1_month" },
										{ title: "Last 1 week", key: "Last_1_week" },
										{ title: "Today", key: "today" },
									]}
									variant={"outlined"}
									onChange={handleChange}
									defaultValue={option}
								/>
							</Grid>
							<Grid item xs={12}>
								{spline && (
									<SplineChartWithLoader
										categories={spline.categories}
										productSentData={spline.credited_amount}
										productReqData={spline.debited_amount}
										profitData={spline.net_profit}
										isLoading={isLoading}
									/>
								)}
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={12} md={8} className={chartContainer}>
					<TableCard
						header={"Pending Invoices"}
						columns={["Invoice", "State", "Status", "Gst", "Total"]}
						rows={invoices}
					/>
				</Grid>
				<Grid item xs={12} md={4} className={chartContainer}>
					{mapData && <NEMapChart StateData={mapData} />}
				</Grid>
			</Grid>
		</>
	);
};

DashboardPage.propTypes = {};

export default DashboardPage;
