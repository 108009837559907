import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";

export default function DisplayTable({ rows, columns }) {
	const useStyles = makeStyles({
		root: {
			"& .MuiTableCell-head": {
				color: "black",
				backgroundColor: "#d9d9d9",
			},
		},
	});
	const classes = useStyles();
	return (
		<TableContainer component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow className={classes.row}>
						{columns.map((column) => (
							<TableCell sx={{ border: 1 }}>
								<b>{Object.keys(column)[0]}</b>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows?.map((row) => (
						<TableRow key={row.name} sx={{ border: 1 }}>
							{columns?.map((col) => (
								<TableCell sx={{ border: 1 }} component="th" scope="row">
									{row[Object.values(col)[0]]}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
