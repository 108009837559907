import { atom, selector } from "recoil";
import { getUser, getNotifications } from "./apis/user.apis";

export const authUser = atom({
	key: "authUser",
	default: "",
});

export const currentUser = selector({
	key: "CurrentUserName",
	get: async () => {
		const user = getUser();
		return await user.data;
		// let user = await new Promise((resolve) => resolve({ id: 1 }));
		// console.log('user', user)
		// return user;
	},
});

export const userNotification = selector({
	key: "Notifications",
	get: async () => {
		const notifications = getNotifications();
		return await notifications.data;
	},
});

