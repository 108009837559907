export default (theme) => ({
	container: {
		minHeight: theme.typography.pxToRem(85),
		padding: theme.typography.pxToRem(5),
	},
	avataarStyle: {
		// backgroundColor: "transparent",
		// borderColor: "#56D9FE",
	},
	headerStyle: {
		fontWeight: "bold",
	},

	outerCard: {
		cursor: "pointer",
		padding: theme.typography.pxToRem(10),
	},

	innerCard: {
		minHeight: theme.typography.pxToRem(224),
		padding: ` ${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(
			42
		)}  ${theme.typography.pxToRem(12)}`,
	},

	bordered: {
		border: "1px solid #14004708",
		borderRadius: theme.typography.pxToRem(10),
	},

	iconContainer: {
		textAlign: "center",
		alignSelf: "center",
	},

	iconButton: {
		padding: theme.typography.pxToRem(4),
	},

	lowerGrid: {
		padding: theme.typography.pxToRem(15),
	},

	imageStyle: {
		width: "100%",
		height: theme.typography.pxToRem(214),
	},

	// headerStyle: {
	// 	fontSize: theme.typography.pxToRem(18),
	// },

	subHeaderStyle: {
		fontSize: theme.typography.pxToRem(12),
	},
	imageStyle: {
		textAlign: "center",
	},

	headerContainer: {
		alignSelf: "center",
	},

	success: {
		color: "green",
	},
	errorStyle: {
		color: "red",
	},
	flexGrid: {
		display: "flex",
	},
});
